import { useEffect, useState } from "react";
import styles from "./budget.module.scss";
import { Autocomplete, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../store";
import { fetchActivityAction, fetchUserListAction, fetchWalletListAction, resetwalletListAction } from "./budgetSlice";
import { Wallet } from "../wallets/walletSlice";
import { Transaction } from "../transactions/transactionSlice";
import { getDecimalNumber, getTransactionValue } from "src/utils/utils";
import { Users } from "../users/usersSlice";
import { debounce } from "lodash";
import { PARTICIPANT } from "src/components/constant/constant";

export default function Budget() {
  const dispatch = useAppDispatch();
  const { users, wallets, transactions } = useAppSelector((state) => state.budget);
  const { userInfo } = useAppSelector((state) => state.auth);
  const [selectedParticipant, setSelectedParticipant] = useState<Users | null>(null);
  const [selectedWallet, setSelectedWallet] = useState<Wallet | null>(null);
  const [searchInput, setSearchInput] = useState<string>("");

  useEffect(() => {
    dispatch(
      fetchUserListAction({
        user_filter: {
          role: PARTICIPANT
        },
        size: 15,
        from: 0
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if ((users.list.length === 1, searchInput.trim().length === 0)) {
      setSelectedParticipant(users?.list[0]);
    }
  }, [users]);

  const handleUserChange = debounce((event: React.ChangeEvent<object>, input: string) => {
    setSearchInput(input);
    if (input.length < 2) return;
    const data = {
      user_filter: {
        full_name: input,
        role: PARTICIPANT
      },
      size: 15,
      from: 0
    };

    dispatch(fetchUserListAction(data));
  }, 500);

  useEffect(() => {
    if (selectedParticipant) {
      dispatch(fetchWalletListAction(String(selectedParticipant?.userId)));
    }
  }, [selectedParticipant]);

  useEffect(() => {
    if (selectedWallet) {
      dispatch(
        fetchActivityAction({
          size: 5,
          from: 0,
          filter: {
            activityName: "NOVATTI_CARD_TRANSACTION",
            walletIds: [String(selectedWallet?.id)]
          },
          userFilter: {
            email: String(userInfo?.email)
          }
        })
      );
    }
  }, [dispatch, selectedWallet, userInfo?.email]);

  return (
    <div className={styles.container}>
      <div className={styles.walletContainer}>
        <div className={styles.selectContainer}>
          <div className={styles.participant}>
            <label>Select participant</label>
            <Autocomplete
              options={users?.list}
              getOptionLabel={(option) => option?.firstName + " " + option?.lastName ?? option}
              filterOptions={(x) => x}
              value={selectedParticipant}
              onInputChange={handleUserChange}
              onChange={(event, newValue) => {
                setSelectedParticipant(newValue);
                setSelectedWallet(null);
                dispatch(resetwalletListAction({ list: [] }));
              }}
              renderInput={(params) => <TextField {...params} placeholder="Select Participant" />}
              renderOption={(props, option) => (
                <li
                  {...props}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start"
                  }}
                >
                  <span>{option?.firstName + " " + option?.lastName}</span>
                  <span style={{ color: "#64748B" }}>{option?.email}</span>
                </li>
              )}
            />
          </div>
          {selectedParticipant?.userId && (
            <div className={styles.wallet}>
              <label>Select Wallet</label>
              <Autocomplete
                options={wallets?.list}
                getOptionLabel={(option) => option.name || ""}
                filterOptions={(x) => x}
                value={selectedWallet}
                onChange={(event, newValue) => {
                  setSelectedWallet(newValue);
                }}
                renderInput={(params) => <TextField {...params} placeholder="Search Wallet" />}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start"
                    }}
                  >
                    <span>{option.name}</span>
                    <span style={{ color: "#64748B" }}>{option.participant?.email}</span>
                  </li>
                )}
              />
            </div>
          )}
        </div>
        {selectedWallet && (
          <div className={styles.amountCard}>
            <div className={styles.title}>Amount To Spend</div>
            <div className={styles.amount}>${getDecimalNumber(selectedWallet?.balance)}</div>
          </div>
        )}
      </div>
      {selectedWallet && (
        <div className={styles.lastTransaction}>
          <h3>Last 5 Transactions</h3>
          <div className={styles.transaction}>
            {transactions?.list?.map((transaction: Transaction) => (
              <div className={styles.transCard} key={transaction.id}>
                <div className={styles.payment}>
                  {getTransactionValue(transaction)?.value}
                  <div
                    className={styles.amount}
                    style={{
                      color: getTransactionValue(transaction)?.amountColor
                    }}
                  >
                    {getTransactionValue(transaction)?.amount}
                  </div>
                </div>
                <div className={styles.paymentdata}>
                  <div
                    className={styles.type}
                    style={{
                      color: transaction?.activityStatus === "APPROVED" ? "green" : "red"
                    }}
                  >
                    {transaction?.activityStatus}
                  </div>
                  <div className={styles.participant}>
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {transaction?.data?.transaction_user
                      ? "Made by " +
                        transaction?.data?.transaction_user?.attributes?.first_name +
                        " " +
                        transaction?.data?.transaction_user?.attributes?.last_name
                      : transaction?.data?.user
                        ? "Made by " +
                          transaction?.data?.user?.attributes?.first_name +
                          " " +
                          transaction?.data?.user?.attributes?.last_name
                        : "---"}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
