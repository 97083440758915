import { ChangeEvent, useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import styles from "./addOrgModal.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { CircularProgress, FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useAppSelector, useAppDispatch } from "src/page/store";
import { countryCodeData } from "src/types/countryCode";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { AddOrgRequest } from "src/network/graphql/organisationService";
import { createInitialOrgAction } from "src/page/onBoarding/onBoardingSlice";

interface AddOrgModalProps {
  handleClose: () => void;
  onSuccess: () => void;
  rootContainrOver?: string;
  closeButton?: boolean;
  orgLoading?: boolean;
  buttonText?: string;
  forParticipant?: boolean;
  Orgtype?: string;
}

export default function AddOrgModal({
  handleClose,
  onSuccess,
  rootContainrOver,
  closeButton = true,
  orgLoading = false,
  buttonText = "Submit",
  forParticipant = false,
  Orgtype
}: AddOrgModalProps) {
  const { userInfo } = useAppSelector((state) => state.auth);

  const [input, setInput] = useState(() => ({
    name: "",
    abn: forParticipant ? "12345678901" : "",
    phoneCountryCode: "+61",
    phone: "",
    type: Orgtype || "",
    line1: "",
    line2: "",
    city: "",
    state: "",
    postcode: "",
    country: "Australia"
  }));

  const [error, setError] = useState({
    name: "",
    abn: "",
    phone: "",
    type: "",
    addressLine1: "",
    city: "",
    state: "",
    postcode: "",
    country: ""
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (forParticipant) {
      setInput((prevInput) => ({
        ...prevInput,
        abn: "12345678901"
      }));
    }
  }, []);

  useEffect(() => {
    if (Orgtype) {
      setInput({
        ...input,
        type: Orgtype
      });
    }
  }, [Orgtype]);

  const handleInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const handleCancelClose = () => {
    handleClose();
  };

  const validateInput: () => boolean = () => {
    if (
      !input.name.trim() ||
      !input.abn.trim() ||
      !input.phone.trim() ||
      !input.type.trim() ||
      !input.line1.trim() ||
      !input.city.trim() ||
      !input.state.trim() ||
      !input.postcode.trim() ||
      !input.country.trim()
    ) {
      setError({
        ...error,
        name: input.name.trim() ? "" : "Company name is required",
        abn: input.abn.trim() ? "" : "Company's Abn is required",

        phone: input.phone.trim()
          ? parsePhoneNumberFromString(input.phoneCountryCode + input.phone)?.isValid()
            ? ""
            : "Invalid contact number"
          : "Company's phone is required",
        type: input.type.trim() ? "" : "Company type is required",
        addressLine1: input.line1.trim() ? "" : "address is required",
        city: input.city.trim() ? "" : "city is required",
        state: input.state.trim() ? "" : "state is required",
        postcode: input.postcode.trim() ? "" : "postcode is required",
        country: input.country.trim() ? "" : "country is required"
      });
      return false;
    }

    if (input.abn.length < 11) {
      setError({
        ...error,
        abn: "ABN number should be equal to 11 digits"
      });
      return false;
    }

    return true;
  };

  const handleAddOrg = () => {
    if (!validateInput()) {
      return;
    }

    const data: AddOrgRequest = {
      name: input.name,
      abn: input.abn,
      phone: input.phoneCountryCode + input.phone,
      type: input.type,
      line1: input.line1,
      line2: input.line2,
      city: input.city,
      state: input.state,
      postcode: input.postcode,
      country: input.country,
      referenceName: `${userInfo?.firstName ?? ""} ${userInfo?.lastName ?? ""}`,
      referenceRole: "SuperAdmin",
      referenceEmail: userInfo?.email ?? "",
      referencePhone: userInfo?.contact ?? "",
      superAdminInitial: "Mr",
      superAdminFirstName: userInfo?.firstName || "",
      superAdminLastName: userInfo?.lastName || "",
      superAdminEmail: userInfo?.email,
      superAdminGender: "male",
      superAdminPhone: userInfo?.contact,
      superAdminUserName: userInfo?.cognitoUserName,
      superAdminaddressLine1: input.line1,
      superAdminaddressLine2: input.line2,
      superAdminCity: input.city,
      superAdminState: input.state,
      superAdminPostcode: input.postcode,
      superAdminCountry: input.country,
      superAdminDob: "",
      address: "",
      id: null
    };

    dispatch(
      createInitialOrgAction(data, () => {
        onSuccess();
      })
    );
  };

  const handleCountryCodeChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;

    setInput({ ...input, [name]: value });
  };

  const handleOrgContactBlur = () => {
    const phoneNumber = parsePhoneNumberFromString(input.phoneCountryCode + input.phone);

    if (!phoneNumber || !phoneNumber.isValid()) {
      setError({
        ...error,
        phone: "Invalid contact number"
      });
    } else {
      setError({
        ...error,
        phone: ""
      });
    }
  };

  return (
    <div className={` ${styles.rootContainer} ${rootContainrOver}`}>
      <main className={styles.modalTop}>
        <form>
          <div className={styles.close}>
            <h5>General Company Info</h5>
            {closeButton && (
              <button onClick={handleCancelClose} aria-label="close">
                <CloseIcon onClick={handleCancelClose} />
              </button>
            )}
          </div>
          {orgLoading ? (
            <div className={styles.progress}>
              <CircularProgress sx={{ color: "#0f172a" }} />
            </div>
          ) : (
            <>
              <div className={styles.generalCompany}>
                <div className={styles.companyName}>
                  <label htmlFor="name">{forParticipant ? "Participant Name" : "Company Name"}:</label>
                  <div className={styles.inputWerror}>
                    <input
                      id="name"
                      type="text"
                      placeholder="Enter Text Here"
                      value={input.name}
                      name="name"
                      onChange={handleInput}
                    />
                    {error.name && (
                      <div className={styles.error}>
                        <ErrorOutlineIcon className={styles.errIcon} />
                        {error.name}
                      </div>
                    )}
                  </div>
                </div>
                {!forParticipant && (
                  <div className={styles.abnName}>
                    <label htmlFor="abn">ABN :</label>
                    <div className={styles.inputWerror}>
                      <input
                        id="abn"
                        type="text"
                        placeholder="Enter Text Here"
                        maxLength={11}
                        value={input.abn}
                        name="abn"
                        onChange={handleInput}
                      />
                      {error.abn && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {error.abn}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className={styles.mobile}>
                  <label htmlFor="orgcontact">Contact Phone :</label>
                  <div className={styles.inputWerror}>
                    <div className={styles.mobileSelect}>
                      <Select
                        value={input.phoneCountryCode || "+61"}
                        onChange={handleCountryCodeChange}
                        displayEmpty
                        name="phoneCountryCode"
                        className={styles.countryCodeSelect}
                        sx={{ height: "50px" }}
                      >
                        {countryCodeData.map((country) => (
                          <MenuItem key={country.code} value={country.code}>
                            {country.code}
                          </MenuItem>
                        ))}
                      </Select>
                      <input
                        id="orgcontact"
                        type="text"
                        placeholder="Enter Text Here"
                        value={input.phone}
                        name="phone"
                        onChange={handleInput}
                        onBlur={handleOrgContactBlur}
                      />
                    </div>
                    {error.phone && (
                      <div className={styles.error}>
                        <ErrorOutlineIcon className={styles.errIcon} />
                        {error.phone}
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.mobile}>
                  <label htmlFor="type">Type :</label>
                  <div className={styles.inputWerror}>
                    <div className={styles.mobileSelect}>
                      <FormControl sx={{ m: 1, margin: 0, width: "100%" }}>
                        <Select
                          id="type"
                          value={input.type}
                          onChange={(e) => {
                            if (e.target.value === "type") {
                              setInput({
                                ...input,
                                type: ""
                              });
                            } else {
                              setInput({
                                ...input,
                                type: e.target.value
                              });
                            }
                          }}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          sx={{
                            height: 50,
                            border: "#e0e2e7",
                            borderRadius: "8px",
                            color: {
                              CORPORATE: "",
                              INDIVIDUAL: "",
                              "": "grey",
                              default: "grey"
                            }[input.type ?? "default"]
                          }}
                        >
                          <MenuItem value="" sx={{ color: "grey" }}>
                            Type
                          </MenuItem>
                          <MenuItem value="CORPORATE">Corporate</MenuItem>
                          <MenuItem value="INDIVIDUAL">Individual</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    {error.type && (
                      <div className={styles.error}>
                        <ErrorOutlineIcon className={styles.errIcon} />
                        {error.type}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className={styles.close}>
                <h5>What Address should we send the card to?</h5>
              </div>
              <div className={styles.companyAddress}>
                <div className={styles.addressLine1}>
                  <label htmlFor="addressline1">Line 1 :</label>
                  <div className={styles.inputWerror}>
                    <input
                      id="addressline1"
                      type="text"
                      placeholder="Enter Text Here"
                      value={input.line1}
                      name="line1"
                      onChange={handleInput}
                    />
                    {error.addressLine1 && (
                      <div className={styles.error}>
                        <ErrorOutlineIcon className={styles.errIcon} />
                        {error.addressLine1}
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.addressLine2}>
                  <label htmlFor="addressline2">Line 2 :</label>
                  <input
                    id="addressline2"
                    type="text"
                    placeholder="Enter Text Here"
                    value={input.line2}
                    name="line2"
                    onChange={handleInput}
                  />
                </div>
                <div className={styles.city}>
                  <label htmlFor="city">City :</label>
                  <div className={styles.inputWerror}>
                    <input
                      id="city"
                      type="text"
                      placeholder="Enter Text Here"
                      value={input.city}
                      name="city"
                      onChange={handleInput}
                    />
                    {error.city && (
                      <div className={styles.error}>
                        <ErrorOutlineIcon className={styles.errIcon} />
                        {error.city}
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.state}>
                  <label htmlFor="state">State :</label>
                  <div className={styles.inputWerror}>
                    <input
                      id="state"
                      type="text"
                      placeholder="Enter Text Here"
                      value={input.state}
                      name="state"
                      onChange={handleInput}
                    />
                    {error.state && (
                      <div className={styles.error}>
                        <ErrorOutlineIcon className={styles.errIcon} />
                        {error.state}
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.postcode}>
                  <label htmlFor="postcode">Postcode :</label>
                  <div className={styles.inputWerror}>
                    <input
                      id="postcode"
                      type="text"
                      placeholder="Enter Text Here"
                      value={input.postcode}
                      name="postcode"
                      onChange={handleInput}
                    />
                    {error.postcode && (
                      <div className={styles.error}>
                        <ErrorOutlineIcon className={styles.errIcon} />
                        {error.postcode}
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.country}>
                  <label htmlFor="country">Country :</label>
                  <div className={styles.inputWerror}>
                    <input
                      id="country"
                      type="text"
                      placeholder="Enter Text Here"
                      value={input.country}
                      name="country"
                      onChange={handleInput}
                    />
                    {error.country && (
                      <div className={styles.error}>
                        <ErrorOutlineIcon className={styles.errIcon} />
                        {error.country}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </form>
      </main>
      <footer className={styles.modalBottom}>
        <div className={styles.referModalBtn}>
          <Stack spacing={2} direction="row">
            <Button className={styles.sendBtn} variant="contained" onClick={handleAddOrg} disabled={orgLoading}>
              {buttonText}
            </Button>
          </Stack>
        </div>
      </footer>
    </div>
  );
}
