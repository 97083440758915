import { FormEventHandler, useEffect, useState } from "react";
import styles from "./auth.module.scss";
import { Button, CircularProgress, IconButton, InputAdornment, MenuItem, Select, TextField } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import {
  loginAction,
  forgetPasswordAction,
  setLoading,
  forgetResetPassword,
  loginSSOAction,
  signUpAction
} from "./loginSlice";
import { useAppDispatch, useAppSelector } from "../store";
import { useNavigate, useSearchParams } from "react-router-dom";
import { spendAbleLogo } from "src/components/nav/logo";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
// import CognitoAuth from "src/components/cognitoAuth/CognitoAuth";
import SetFirstTimePassword from "src/components/setFirstTimePassword/SetFirstTimePassword";
// import { isHostPrefixPresent } from "src/utils/utils";
import CognitoAuth from "src/components/cognitoAuth/CognitoAuth";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { countryCodeData } from "src/types/countryCode";
import { checkEmailAvailability, checkUserNameAvailability } from "src/network/graphql/userService";
import { userNameValidation } from "src/utils/utils";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { immediateToast } from "izitoast-react";
import { generateUsername } from "src/utils/utils";

function Login() {
  const [rightPanelActive, setRightPanelActive] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [signUpEmail, setSignUpEmail] = useState<string>("");
  const [signUpPassword, setSignUpPassword] = useState<string>("");
  const [signUpConfirmPassword, setSignUpConfirmPassword] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [agreeToTerms, setAgreeToTerms] = useState<boolean>(false);
  const [contact, setContact] = useState<string>("");
  const [phoneCountryCode, setPhoneCountryCode] = useState<string>("+61");
  const [forgotPassword, setForgotPassword] = useState<boolean>(false);
  const [registeredEmail, setRegisteredEmail] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { loading, forgetPasswordLoading } = useAppSelector((state) => state.auth);
  const { showSetPassword } = useAppSelector((state) => state.user);
  const [emailError, setEmailError] = useState({
    email: "",
    loginemail: "",
    loginPassword: "",
    signUpfname: "",
    signUplname: "",
    signUpcontact: "",
    signUpemail: "",
    signUpPassword: "",
    signUpConfirmPassword: "",
    signUpUsername: "",
    verifCode: "",
    newResetPassword: "",
    tnc: ""
  });
  const [resetPassword, setResetPassword] = useState<boolean>(false);
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [newResetPassword, setNewResetPassword] = useState<string>("");
  const [showSpendableLogin, setShowSpendableLogin] = useState<boolean>(false);
  const [signUpLoading, setSignUpLoading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get("code");
  const [showSignUpPassword, setshowSignUpPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    if (process.env.REACT_APP_BUILD_ENV !== "sso") {
      setShowSpendableLogin(true);
    }
  }, []);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (searchParams.get("signUp")) {
      setRightPanelActive(true);
    } else {
      setRightPanelActive(false);
    }
  }, [searchParams]);

  useEffect(() => {
    if (searchParams.get("forgotPassword")) {
      setForgotPassword(true);
    } else {
      setForgotPassword(false);
    }
  }, [searchParams]);

  const handleSignUpClick = () => {
    // window.open("https://spendable.com.au/freetrial", "_blank");
    setSearchParams({ signUp: "true" });
  };

  const handleForgotPasswordClick = () => {
    setSearchParams({ forgotPassword: "true" });
  };

  const handleLoginClick = () => {
    searchParams.delete("forgotPassword");
    setSearchParams(searchParams);
  };

  const handleSignInClick = () => {
    searchParams.delete("signUp");
    setSearchParams(searchParams);
  };

  const handleSignUp: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    const usernameError = userNameValidation(username);
    if (usernameError) {
      setEmailError({
        ...emailError,
        signUpUsername: usernameError
      });
      return;
    }

    if (
      !firstName.trim() ||
      !lastName.trim() ||
      !contact.trim() ||
      !signUpEmail.trim() ||
      !signUpPassword.trim() ||
      !signUpConfirmPassword.trim() ||
      !username.trim() ||
      !agreeToTerms
    ) {
      setEmailError({
        ...emailError,
        signUpfname: firstName.trim() ? "" : "First Name is required",
        signUplname: lastName.trim() ? "" : "Last Name is required",
        // eslint-disable-next-line no-nested-ternary
        signUpcontact: contact.trim()
          ? parsePhoneNumberFromString(phoneCountryCode + contact)?.isValid()
            ? ""
            : "Invalid contact number"
          : "Contact number is required",
        signUpemail: signUpEmail.trim() ? "" : "Email is required",
        signUpPassword: signUpPassword.trim() ? "" : "Password is required",
        // eslint-disable-next-line no-nested-ternary
        signUpConfirmPassword: signUpConfirmPassword.trim()
          ? signUpConfirmPassword !== signUpPassword
            ? "Passwords do not match"
            : ""
          : "Confirm Password is required",
        signUpUsername: username.trim() ? userNameValidation(username) : "Username is required",
        tnc: agreeToTerms ? "" : "You must agree to the terms and conditions"
      });
      return;
    }

    setSignUpLoading(true);
    immediateToast("info", {
      message: "Hold tight, we are setting up your account",
      timeout: 3000,
      position: "topCenter"
    });
    const isUserNameAvailable = await checkUserNameAvailability(username);

    if (!isUserNameAvailable.data.is_username_available) {
      setSignUpLoading(false);
      setEmailError({
        ...emailError,
        signUpUsername: "username already exists"
      });
      return;
    }
    const isEmailAvailable = await checkEmailAvailability(signUpEmail);

    if (!isEmailAvailable.data.is_email_available) {
      setSignUpLoading(false);
      setEmailError({
        ...emailError,
        signUpemail: "email already exists"
      });
      return;
    }

    const formattedFirstName = firstName.trim().replace(/\s+/g, "_");
    const formattedLastName = lastName.trim().replace(/\s+/g, "_");

    const data = {
      userName: username,
      email: signUpEmail,
      password: signUpPassword,
      firstName: formattedFirstName,
      lastName: formattedLastName,
      contact: String(phoneCountryCode + contact)
    };

    dispatch(
      signUpAction(data, () => {
        setSignUpLoading(false);
        setSignUpEmail("");
        setSignUpPassword("");
        setSignUpConfirmPassword("");
        setAgreeToTerms(false);
        setFirstName("");
        setLastName("");
        setContact("");
        setUsername("");
        setEmailError({
          ...emailError,
          signUpfname: "",
          signUplname: "",
          signUpcontact: "",
          signUpemail: "",
          signUpPassword: "",
          signUpConfirmPassword: "",
          signUpUsername: "",
          tnc: ""
        });
        searchParams.delete("signUp");
        setSearchParams(searchParams);
      })
    );
  };

  const handleSignIn: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    if (!email.trim() || !password.trim()) {
      setEmailError({
        ...emailError,
        loginemail: email.trim() ? "" : "Email is required",
        loginPassword: password.trim() ? "" : "Password is required"
      });
      return;
    }

    dispatch(
      loginAction(
        { email, password },
        () => {
          navigate("/");
        },
        () => {
          navigate("/on-boarding");
        }
      )
    );
  };

  const handleForgotPassword = () => {
    if (resetPassword) {
      if (!verificationCode.trim() || !newResetPassword.trim()) {
        setEmailError({
          ...emailError,
          verifCode: verificationCode ? "" : "Verification Code is required",
          newResetPassword: newResetPassword ? "" : "password is required"
        });
        return;
      }

      dispatch(
        forgetResetPassword(registeredEmail, newResetPassword, verificationCode, () => {
          setResetPassword(false);
          searchParams.delete("forgotPassword");
          setSearchParams(searchParams);
          setRegisteredEmail("");
          setVerificationCode("");
          setNewResetPassword("");
        })
      );
    } else {
      if (!registeredEmail.trim()) {
        setEmailError({ ...emailError, email: "Email is required" });
        return;
      }

      dispatch(forgetPasswordAction(registeredEmail)).then(() => {
        // setRegisteredEmail("");
        setResetPassword(true);
      });
    }
  };

  useEffect(() => {
    dispatch(setLoading(false));
  }, []);

  useEffect(() => {
    if (code) {
      dispatch(
        loginSSOAction(code, () => {
          console.log("logging in with SSO");
          navigate("/");
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  const handleContactBlur = () => {
    const phoneNumber = parsePhoneNumberFromString(phoneCountryCode + contact);

    if (!phoneNumber || !phoneNumber.isValid()) {
      setEmailError({
        ...emailError,
        signUpcontact: "Invalid contact number"
      });
    } else {
      setEmailError({
        ...emailError,
        signUpcontact: ""
      });
    }
  };

  const generateAndSetUsername = async () => {
    if (firstName && lastName) {
      const formattedFirstName = firstName.trim().replace(/\s+/g, "_");
      const formattedLastName = lastName.trim().replace(/\s+/g, "_");

      const username = await generateUsername(formattedFirstName, formattedLastName);

      if (username) {
        setUsername(username);
      } else {
        setUsername("");
      }
    }
  };
  return (
    <div className={`${styles.container} ${rightPanelActive ? styles.rightPanelActive : ""}`} id="container">
      <div className={`${styles.formContainer} ${styles.signUpContainer}`}>
        <form onSubmit={handleSignUp}>
          <div className={styles.formIn}>
            {loading ? (
              <CircularProgress sx={{ color: "#0f172a" }} />
            ) : (
              <>
                <div className={styles.logo}>
                  <div className={styles.logoL}>{spendAbleLogo}</div>
                  <div className={styles.logoR}>
                    <h1 className={styles.title}>SpendAble</h1>
                    <div className={styles.subTitle}>Enabling Choices, Empowering Change</div>
                  </div>
                </div>
                <div className={styles.header}>
                  <h1>Sign up</h1>
                  <p>If it&apos;s your first time here, you&apos;re in the right place</p>
                </div>
                <div className={styles.formInput}>
                  <div className={styles.name}>
                    <div className={styles.firstName}>
                      <label htmlFor="firstName">First Name</label>
                      <input
                        type="text"
                        id="firstName"
                        placeholder="first name"
                        value={firstName}
                        onChange={(e) => {
                          const value = e.target.value;
                          setFirstName(value);
                        }}
                        onBlur={() => generateAndSetUsername()}
                      />
                      {emailError.signUpfname && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {emailError.signUpfname}
                        </div>
                      )}
                    </div>
                    <div className={styles.lastName}>
                      <label htmlFor="lastName">Last Name</label>
                      <input
                        type="text"
                        id="lastName"
                        placeholder="last name"
                        value={lastName}
                        onChange={(e) => {
                          const value = e.target.value;
                          setLastName(value);
                        }}
                        onBlur={() => generateAndSetUsername()}
                      />
                      {emailError.signUplname && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {emailError.signUplname}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.contact}>
                    <label htmlFor="contact">Phone Number</label>
                    <div className={styles.contactCode}>
                      <Select
                        value={phoneCountryCode || "+61"}
                        onChange={(e) => setPhoneCountryCode(e.target.value)}
                        displayEmpty
                        name="phoneCountryCode"
                        className={styles.countryCodeSelect}
                        sx={{ height: "40px" }}
                        MenuProps={{ sx: { maxHeight: 250 } }}
                        disabled
                      >
                        {countryCodeData.map((country) => (
                          <MenuItem key={country.code} value={country.code}>
                            {country.code}
                          </MenuItem>
                        ))}{" "}
                        */
                      </Select>
                      <input
                        type="number"
                        id="contact"
                        placeholder="e.g. 0412345678"
                        value={contact}
                        onChange={(e) => setContact(e.target.value)}
                        onBlur={handleContactBlur}
                      />
                    </div>
                    {emailError.signUpcontact && (
                      <div className={styles.error}>
                        <ErrorOutlineIcon className={styles.errIcon} />
                        {emailError.signUpcontact}
                      </div>
                    )}
                  </div>
                  <div className={styles.email}>
                    <label htmlFor="email">Email Address</label>
                    <input
                      type="text"
                      id="email"
                      placeholder="example@gmail.com"
                      value={signUpEmail}
                      onChange={(e) => {
                        const value = e.target.value.trim().toLowerCase();
                        setSignUpEmail(value);
                      }}
                    />
                    {emailError.signUpemail && (
                      <div className={styles.error}>
                        <ErrorOutlineIcon className={styles.errIcon} />
                        {emailError.signUpemail}
                      </div>
                    )}
                  </div>
                  <div className={styles.username}>
                    <label htmlFor="username">Username</label>
                    <input
                      type="text"
                      id="username"
                      placeholder="i.e. (e.g. John.smith)"
                      value={username}
                      onChange={(e) => setUsername(e.target.value.toLowerCase())}
                    />
                    {emailError.signUpUsername && (
                      <div className={styles.error}>
                        <ErrorOutlineIcon className={styles.errIcon} />
                        {emailError.signUpUsername}
                      </div>
                    )}
                  </div>
                  <div className={styles.password}>
                    <label htmlFor="pass">Password</label>
                    <TextField
                      id="pass"
                      type={showSignUpPassword ? "text" : "password"}
                      placeholder="Create a password"
                      value={signUpPassword}
                      onChange={(e) => setSignUpPassword(e.target.value)}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setshowSignUpPassword(!showSignUpPassword)}
                              aria-label="toggle password visibility"
                            >
                              {showSignUpPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                    {emailError.signUpPassword && (
                      <div className={styles.error}>
                        <ErrorOutlineIcon className={styles.errIcon} />
                        {emailError.signUpPassword}
                      </div>
                    )}
                  </div>

                  <div className={styles.password}>
                    <label htmlFor="confirmpass">Confirm Password</label>
                    <TextField
                      id="confirmpass"
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder="Re-enter password"
                      value={signUpConfirmPassword}
                      onChange={(e) => setSignUpConfirmPassword(e.target.value)}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                              aria-label="toggle confirm password visibility"
                            >
                              {showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                    {emailError.signUpConfirmPassword && (
                      <div className={styles.error}>
                        <ErrorOutlineIcon className={styles.errIcon} />
                        {emailError.signUpConfirmPassword}
                      </div>
                    )}
                  </div>
                  <div className={styles.checkboxContainer}>
                    <input
                      type="checkbox"
                      checked={agreeToTerms}
                      onChange={(e) => setAgreeToTerms(e.target.checked)}
                      aria-label="term and condition"
                    />
                    <span>
                      I agree to the
                      <b
                        className={styles.tnc}
                        onClick={() => {
                          window.open(
                            "https://storage.googleapis.com/msgsndr/Pkn7yggrsCLAHtfI3qCv/media/666a85aeb1b532560e770720.pdf",
                            "_blank"
                          );
                        }}
                      >
                        {" "}
                        Terms and conditions
                      </b>
                    </span>
                  </div>
                  {emailError.tnc && (
                    <div className={styles.error}>
                      <ErrorOutlineIcon className={styles.errIcon} />
                      {emailError.tnc}
                    </div>
                  )}
                </div>
                <div className={styles.signUp}>
                  <Button
                    type="submit"
                    variant="contained"
                    aria-label="Sign Up"
                    className={styles.SignUpBtn}
                    disabled={loading || signUpLoading}
                  >
                    {signUpLoading ? <CircularProgress sx={{ color: "white" }} /> : "Sign Up"}
                  </Button>
                </div>
                <div className={styles.overlayContainerL}>
                  <div className={styles.overlayL}>
                    <div className={`${styles.overlayPanelL} ${styles.overlayLeft}`}>
                      <span>
                        Already have an account?
                        <b className={styles.ghost} id="signIn" onClick={handleSignInClick}>
                          . Sign in
                        </b>
                      </span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </form>
      </div>
      {rightPanelActive ? null : (
        <div className={`${styles.formContainer} ${styles.signInContainer}`}>
          <form onSubmit={handleSignIn}>
            <div className={styles.formIn}>
              {loading ? (
                <CircularProgress sx={{ color: "#0f172a" }} />
              ) : (
                <>
                  <div className={styles.logo}>
                    <div className={styles.logoL}>{spendAbleLogo}</div>
                    <div className={styles.logoR}>
                      <h1 className={styles.title}>SpendAble</h1>
                      <div className={styles.subTitle}>Enabling Choices, Empowering Change</div>
                    </div>
                  </div>
                  {showSetPassword && <SetFirstTimePassword handleLoginClick={handleLoginClick} />}
                  {forgotPassword && !showSetPassword ? (
                    <>
                      <div className={styles.header}>
                        <h1>Password Recovery</h1>
                      </div>
                      {forgetPasswordLoading ? (
                        <CircularProgress sx={{ color: "#0f172a" }} />
                      ) : (
                        <>
                          <div className={styles.formInput}>
                            <div className={styles.email}>
                              <label htmlFor="RegistEmail">Registered Email</label>
                              <TextField
                                type="email"
                                id="RegistEmail"
                                placeholder="EMAIL"
                                value={registeredEmail}
                                disabled={resetPassword}
                                onChange={(e) => {
                                  setRegisteredEmail(e.target.value);
                                  if (!e.target.value) {
                                    setEmailError({ ...emailError, email: "Email is required" });
                                  } else {
                                    setEmailError({ ...emailError, email: "" });
                                  }
                                }}
                                sx={{
                                  width: "100%",
                                  borderRadius: "6px",
                                  borderColor: "#ced4da",
                                  input: {
                                    backgroundColor: resetPassword ? "#90909024 !important" : ""
                                  }
                                }}
                              />
                              {resetPassword && (
                                <div
                                  className={styles.editEmail}
                                  onClick={() => {
                                    setResetPassword(false);
                                  }}
                                >
                                  Edit Email
                                </div>
                              )}
                              {emailError.email && (
                                <div className={styles.error}>
                                  <ErrorOutlineIcon className={styles.errIcon} />
                                  {emailError.email}
                                </div>
                              )}
                            </div>
                            {resetPassword && (
                              <>
                                <div className={styles.email}>
                                  <label htmlFor="verificationCode">Verify Code</label>
                                  <TextField
                                    type="text"
                                    id="verificationCode"
                                    placeholder="Verification Code"
                                    value={verificationCode}
                                    onChange={(e) => {
                                      setVerificationCode(e.target.value);
                                      if (!e.target.value) {
                                        setEmailError({ ...emailError, verifCode: "Verification Code is required" });
                                      } else {
                                        setEmailError({ ...emailError, verifCode: "" });
                                      }
                                    }}
                                    sx={{ width: "100%", borderRadius: "6px", borderColor: "#ced4da" }}
                                  />
                                  {emailError.verifCode && (
                                    <div className={styles.error}>
                                      <ErrorOutlineIcon className={styles.errIcon} />
                                      {emailError.verifCode}
                                    </div>
                                  )}
                                </div>
                                <div className={styles.email}>
                                  <label htmlFor="newResetPassword">New Password</label>
                                  <TextField
                                    type="text"
                                    id="newResetPassword"
                                    placeholder="Password"
                                    value={newResetPassword}
                                    onChange={(e) => {
                                      setNewResetPassword(e.target.value);
                                      if (!e.target.value) {
                                        setEmailError({ ...emailError, newResetPassword: "Password is required" });
                                      } else {
                                        setEmailError({ ...emailError, newResetPassword: "" });
                                      }
                                    }}
                                    sx={{ width: "100%", borderRadius: "6px", borderColor: "#ced4da" }}
                                  />
                                  {emailError.newResetPassword && (
                                    <div className={styles.error}>
                                      <ErrorOutlineIcon className={styles.errIcon} />
                                      {emailError.newResetPassword}
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                          <div className={styles.signIn}>
                            <Button
                              variant="contained"
                              aria-label="Reset Password"
                              className={styles.SignInBtn}
                              disabled={forgetPasswordLoading}
                              onClick={handleForgotPassword}
                            >
                              {resetPassword ? "Confirm" : "Send"}
                            </Button>
                          </div>
                          <div className={styles.overlayContainerR}>
                            <div className={styles.overlayR}>
                              <div className={`${styles.overlayPanelR} ${styles.overlayRight}`}>
                                <span>
                                  Go Back To
                                  <b onClick={handleLoginClick}>. Login</b>
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    !showSetPassword && (
                      <>
                        <div className={styles.header}>
                          <h1>Welcome back</h1>
                        </div>
                        {showSpendableLogin ? (
                          <>
                            <div className={styles.formInput}>
                              <div className={styles.email}>
                                <label htmlFor="emailAdd">Email Address</label>
                                <TextField
                                  type="email"
                                  id="emailAdd"
                                  placeholder="EMAIL"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  sx={{ width: "100%", borderRadius: "6px", borderColor: "#ced4da" }}
                                />
                                {emailError.loginemail && (
                                  <div className={styles.error}>
                                    <ErrorOutlineIcon className={styles.errIcon} />
                                    {emailError.loginemail}
                                  </div>
                                )}
                              </div>
                              <div className={styles.password}>
                                <label htmlFor="passw">Password</label>
                                <TextField
                                  type={showPassword ? "text" : "password"}
                                  id="passw"
                                  placeholder="PASSWORD"
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={() => setShowPassword(!showPassword)}
                                        >
                                          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                      </InputAdornment>
                                    )
                                  }}
                                  sx={{ width: "100%", borderRadius: "6px", borderColor: "#ced4da" }}
                                />
                                {emailError.loginPassword && (
                                  <div className={styles.error}>
                                    <ErrorOutlineIcon className={styles.errIcon} />
                                    {emailError.loginPassword}
                                  </div>
                                )}
                              </div>
                              <div className={styles.checkboxContainer}>
                                <div className={styles.checkboxL}></div>
                                {process.env.REACT_APP_BUILD_ENV !== "sso" && (
                                  <div className={styles.checkboxR}>
                                    <a href="#" onClick={handleForgotPasswordClick}>
                                      Forgot Password
                                    </a>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className={styles.signIn}>
                              <Button
                                type="submit"
                                variant="contained"
                                className={styles.SignInBtn}
                                disabled={loading}
                                aria-label="Sign In"
                              >
                                Sign In
                              </Button>
                            </div>
                            <div className={styles.overlayContainerR}>
                              <div className={styles.overlayR}>
                                <div className={`${styles.overlayPanelR} ${styles.overlayRight}`}>
                                  {process.env.REACT_APP_BUILD_ENV !== "sso" ? (
                                    <span>
                                      Don&apos;t have an account?
                                      <b className={styles.ghost} id="signUp" onClick={handleSignUpClick}>
                                        . Sign up
                                      </b>
                                    </span>
                                  ) : (
                                    <span>
                                      <ArrowBackIcon fontSize="small" sx={{ width: "15px" }} />
                                      <b
                                        className={styles.ghost}
                                        id="signUp"
                                        onClick={() => setShowSpendableLogin(false)}
                                      >
                                        Go back
                                      </b>
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <CognitoAuth />
                            {/* <div className={styles.divider}>
                              <hr />
                              OR
                              <hr />
                            </div>
                            <Button
                              variant="contained"
                              className={styles.Spendablebtn}
                              disabled={loading}
                              onClick={() => setShowSpendableLogin(true)}
                            >
                              Login with Spendable
                            </Button> */}
                          </>
                        )}
                      </>
                    )
                  )}
                </>
              )}
            </div>
          </form>
        </div>
      )}
      <div className={styles.overlayContainer}>
        <div className={styles.overlay}>
          <div className={`${styles.overlayPanel} ${styles.overlayLeft}`}>
            <div className={styles.leftBg}>
              <div className={styles.leftCard}>
                <div className={styles.headText}>
                  <span>Support good </span>
                  <span>money handling</span>
                </div>
                <div className={styles.midText}>
                  Access the tools and accounts to safely handle money for older people and people with disability
                </div>
                <div className={styles.bottomText}>
                  <div className={styles.icon}>
                    <AvatarGroup className={styles.AvatarGroup}>
                      <Avatar
                        alt="Remy Sharp"
                        src={"https://randomuser.me/api/portraits/men/1.jpg"}
                        className={styles.avatar}
                      />
                      <Avatar
                        alt="Travis Howard"
                        src={"https://randomuser.me/api/portraits/women/2.jpg"}
                        className={styles.avatar}
                      />
                      <Avatar
                        alt="Cindy Baker"
                        src={"https://randomuser.me/api/portraits/men/3.jpg"}
                        className={styles.avatar}
                      />
                      <Avatar
                        alt="Agnes Walker"
                        src={"https://randomuser.me/api/portraits/women/4.jpg"}
                        className={styles.avatar}
                      />
                    </AvatarGroup>
                  </div>
                  <span>Join 1,000’s of other people!</span>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.overlayPanel} ${styles.overlayRight}`}>
            <div className={styles.rightBg}>
              <div className={styles.rightCard}>
                <div className={styles.topText}>You&apos;re part of the solution to</div>
                <div className={styles.bottomText}>financial abuse</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
