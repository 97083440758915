import { ChangeEvent, useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import styles from "./userListFilter.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete, FormControl, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "src/page/store";
import { useSearchParams } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { debounce } from "lodash";
import { fetchUserListAction, resetUserList } from "src/page/wallets/walletSlice";
import { PARTICIPANT } from "src/components/constant/constant";
import { resetWalletFilters, setWalletFilters } from "src/page/filtersSlice";
import { User } from "src/types/commonTypes";
interface FilterTransModalProps {
  handleClose: () => void;
  handleFilter: () => void;
}
export default function UserListFilter({ handleClose, handleFilter }: FilterTransModalProps) {
  const [error, setError] = useState({ Amt: "", Date: "" });
  const [searchParams, setSearchParams] = useSearchParams();
  const { wallet: walletFilters } = useAppSelector((state) => state.filters);
  const [status, setStatus] = useState<string | null>(walletFilters.status);
  const [type, setType] = useState<string[] | null>(walletFilters.type);
  const [selectedUsers, setSelectedUsers] = useState<User[]>(walletFilters.user || []);
  const [startDate, setStartDate] = useState<string | null>(walletFilters.startDate);
  const [endDate, setEndDate] = useState<string | null>(walletFilters.endDate);
  const [minAmt, setMinAmt] = useState<string | null>(walletFilters.minAmount);
  const [maxAmt, setMaxAmt] = useState<string | null>(walletFilters.maxAmount);

  const { users } = useAppSelector((state) => state.wallet);
  const dispatch = useAppDispatch();

  const handleCancel = () => {
    handleClose();
    dispatch(resetUserList([]));
  };

  const handleTypeChange = (event: SelectChangeEvent<string[]>) => {
    setType(event.target.value as string[]);
  };

  const handleApplyFilter = () => {
    if (maxAmt !== null && maxAmt !== "" && Number(minAmt) > Number(maxAmt)) {
      setError({
        ...error,
        Amt: "Maximum amount should always be larger than minimum amount"
      });
      return;
    }

    if (startDate && endDate && new Date(startDate) > new Date(endDate) && endDate !== "") {
      setError({
        ...error,
        Date: "End date should always be larger than start date"
      });
      return;
    }

    dispatch(
      setWalletFilters({
        user: selectedUsers,
        startDate,
        endDate,
        minAmount: minAmt,
        maxAmount: maxAmt,
        status,
        type
      })
    );
    setSearchParams(searchParams);
    handleFilter();
    handleCancel();
  };

  const handleReset = () => {
    dispatch(resetWalletFilters());
    setSelectedUsers([]);
    setStartDate(null);
    setEndDate(null);
    setMinAmt(null);
    setMaxAmt(null);
    setStatus("ACTIVE");
    setType(["GENERAL", "CASH"]);
    setError({ Amt: "", Date: "" });
    handleFilter();
    handleCancel();
  };

  useEffect(() => {
    if (maxAmt !== null && maxAmt !== "" && Number(minAmt) > Number(maxAmt)) {
      setError({
        ...error,
        Amt: "Maximum amount should always be larger than minimum amount"
      });
    } else {
      setError({
        ...error,
        Amt: ""
      });
    }
  }, [minAmt, maxAmt, error]);

  useEffect(() => {
    if (startDate && endDate && new Date(startDate) > new Date(endDate) && endDate !== "") {
      setError({
        ...error,
        Date: "End date should always be larger than start date"
      });
    } else {
      setError({
        ...error,
        Date: ""
      });
    }
  }, [startDate, endDate, error]);

  const fetchOption = debounce((inputValue: string) => {
    if (!inputValue && inputValue?.length < 2) return [];

    const data = {
      user_filter: {
        full_name: inputValue,
        role: PARTICIPANT
      },
      size: 15,
      from: 0
    };

    dispatch(fetchUserListAction(data));
  }, 500);

  const handleInputChange = (event: ChangeEvent<object>, newInputValue: string) => {
    fetchOption(newInputValue);
  };

  return (
    <div className={styles.referModalBox}>
      <main className={styles.referModalTop}>
        <form>
          <div className={styles.close}>
            <h5>Filter Wallet</h5>
            <button onClick={handleCancel} aria-label="close">
              <CloseIcon onClick={handleCancel} />
            </button>
          </div>

          <div className={styles.filterByDate}>
            <div className={styles.head}>Filter by Date</div>
            <div className={styles.date}>
              <div className={styles.startDate}>
                <label htmlFor="startdate">Start Date</label>
                <TextField
                  id="startdate"
                  type="date"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </div>
              <div className={styles.endDate}>
                <label htmlFor="enddate">End Date</label>
                <TextField
                  id="enddate"
                  type="date"
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                    classes: {
                      root: "MuiInputLabel-outlined"
                    }
                  }}
                />
              </div>
            </div>
            {error.Date && (
              <div className={styles.error}>
                <ErrorOutlineIcon className={styles.errIcon} />
                {error.Date}
              </div>
            )}
          </div>
          <div className={styles.filterByAmt}>
            <div className={styles.head}>Filter by Amount</div>
            <div className={styles.amt}>
              <div className={styles.min}>
                <label htmlFor="minamt">Minimum</label>
                <input
                  id="minamt"
                  type="number"
                  placeholder="$0"
                  value={minAmt ? Number(minAmt) : undefined}
                  onChange={(e) => {
                    setMinAmt(e.target.value);
                  }}
                />
              </div>
              <div className={styles.max}>
                <label htmlFor="maxamt">Maximum</label>
                <input
                  id="maxamt"
                  type="number"
                  placeholder="$0"
                  value={maxAmt ? Number(maxAmt) : undefined}
                  onChange={(e) => {
                    setMaxAmt(e.target.value);
                  }}
                />
              </div>
            </div>
            {error.Amt && (
              <div className={styles.error}>
                <ErrorOutlineIcon className={styles.errIcon} />
                {error.Amt}
              </div>
            )}
          </div>
          <div className={styles.statusType}>
            <div className={styles.status}>
              <div className={styles.byUserHead}>
                <label htmlFor="status" className={styles.userlabel}>
                  By Status
                </label>
              </div>
              <FormControl>
                <Select
                  id="status"
                  aria-label="select status"
                  value={status || ""}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    height: 56,
                    color: {
                      ACTIVE: "",
                      INACTIVE: "",
                      status: "grey",
                      default: "grey"
                    }[status != null ? status + "" : "default"]
                  }}
                >
                  <MenuItem value="" sx={{ color: "grey" }}>
                    None
                  </MenuItem>
                  <MenuItem value="ACTIVE">Active</MenuItem>
                  <MenuItem value="INACTIVE">Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className={styles.type}>
              <div className={styles.byUserHead}>
                <label htmlFor="wallettype" className={styles.userlabel}>
                  By Wallet Type
                </label>
              </div>
              <FormControl>
                <Select
                  multiple
                  value={type ?? []}
                  onChange={handleTypeChange}
                  displayEmpty
                  renderValue={(selected) => (selected as string[]).join(", ") || "None"}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="GENERAL">General</MenuItem>
                  <MenuItem value="SPENDING">Spending</MenuItem>
                  <MenuItem value="CASH">Cash</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className={styles.addUser}>
            <div className={styles.byUser}>
              <div className={styles.byUserHead}>
                <label className={styles.userlabel}>By User</label>
              </div>
              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={users.list}
                disableCloseOnSelect
                getOptionLabel={(option: any) => option?.firstName ?? option}
                onInputChange={handleInputChange}
                value={selectedUsers}
                filterOptions={(x) => x}
                onChange={(event, newValue) => {
                  setSelectedUsers(newValue);
                }}
                renderInput={(params) => <TextField {...params} placeholder="Users...." />}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start"
                    }}
                  >
                    <span>{option?.firstName + " " + option?.lastName}</span>
                    <span style={{ color: "#64748B" }}>{option?.email}</span>
                  </li>
                )}
              />
            </div>
          </div>
        </form>
      </main>
      <footer className={styles.referModalBottom}>
        <div className={styles.referModalBtn}>
          <Stack spacing={2} direction="row">
            <Button className={styles.cancelBtn} variant="text" onClick={handleReset} aria-label="secondary button">
              Reset Filter
            </Button>
            <Button
              className={styles.sendBtn}
              variant="contained"
              onClick={handleApplyFilter}
              aria-label="primary button"
            >
              Apply Filter
            </Button>
          </Stack>
        </div>
      </footer>
    </div>
  );
}
