import { Autocomplete, Button, CircularProgress, TextField } from "@mui/material";
import styles from "./newPaymentCards.module.scss";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/page/store";
import { Users } from "src/page/users/usersSlice";
import { Wallet } from "src/page/wallets/walletSlice";
import { fetchUserListAction, fetchWalletListAction, resetwalletListAction } from "src/page/newPayment/newPaymentSlice";
import { addTransactionRecord } from "src/page/newPayment/newPaymentSlice";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { adjustTransactionRequest } from "src/network/graphql/transactionService";
import { immediateToast } from "izitoast-react";
import { debounce } from "lodash";

interface payCashProp {
  onClose: (_value: boolean) => void;
  cb?: (_value: boolean) => void;
}

export default function PayCash({ onClose }: payCashProp) {
  const dispatch = useAppDispatch();
  const { users, wallets, transactionLoading } = useAppSelector((state) => state.newPayment);
  const [selectedParticipant, setSelectedParticipant] = useState<Users | null>(null);
  const [selectedWallet, setSelectedWallet] = useState<Wallet | null>(null);
  const [storeName, setStoreName] = useState<string>("");
  const [cashAmount, setCashAmount] = useState<number | string>("");
  const [searchInput, setSearchInput] = useState<string>("");

  const [error, setError] = useState({
    participant: "",
    wallet: "",
    store: "",
    amount: ""
  });

  useEffect(() => {
    dispatch(
      fetchUserListAction({
        user_filter: {
          role: "PARTICIPANT"
        },
        size: 100,
        from: 0
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (users.list.length === 1 && searchInput.trim().length === 0) {
      setSelectedParticipant(users.list[0]);
    }
  }, [users.list, searchInput]);

  useEffect(() => {
    if (selectedParticipant) {
      dispatch(fetchWalletListAction(String(selectedParticipant.userId)));
    } else {
      dispatch(resetwalletListAction({ list: [] }));
    }
  }, [selectedParticipant, dispatch]);

  const handleUserChange = debounce((event: React.ChangeEvent<object>, input: string) => {
    setSearchInput(input);
    if (input.length < 2) return;
    const data = {
      user_filter: {
        full_name: input,
        role: "PARTICIPANT"
      },
      size: 15,
      from: 0
    };

    dispatch(fetchUserListAction(data));
  }, 500);

  const handleBack = () => {
    onClose(false);
  };

  const handleConfirm = () => {
    if (!selectedParticipant || !selectedWallet?.id || !storeName || !cashAmount) {
      setError({
        participant: selectedParticipant ? "" : "Select a participant",
        wallet: selectedWallet?.id ? "" : "Select a wallet",
        store: storeName ? "" : "Enter the store name",
        amount: cashAmount ? "" : "Enter an amount"
      });
      immediateToast("error", {
        message: "Please fill all required fields",
        timeout: 3000,
        position: "topCenter"
      });
      return;
    }

    const data: adjustTransactionRequest = {
      wallet_id: String(selectedWallet.id),
      amount: parseFloat(String(cashAmount)),
      merchantName: storeName,
      transaction_user_id: selectedParticipant.userId,
      currency: "AUD"
    };

    dispatch(
      addTransactionRecord(data, () => {
        setSelectedParticipant(null);
        setSelectedWallet(null);
        setStoreName("");
        setCashAmount("");
      })
    );
  };

  /*
  const handleCancel = () => {
    onClose(false);
  };
  */

  return (
    <div className={styles.newPaymentCard}>
      <header>
        <h1>Pay Cash</h1>
      </header>
      <main>
        <div>
          <Autocomplete
            options={users?.list}
            filterOptions={(x) => x}
            getOptionLabel={(option) => option.firstName + " " + option.lastName}
            value={selectedParticipant}
            onChange={(event, newValue) => {
              setSelectedParticipant(newValue);
              setError({
                ...error,
                participant: ""
              });
            }}
            onInputChange={handleUserChange}
            renderInput={(params) => <TextField {...params} label="Select Participant" />}
          />
          {error.participant && (
            <div className={styles.error}>
              <ErrorOutlineIcon className={styles.errIcon} />
              {error.participant}
            </div>
          )}
        </div>

        <div>
          <Autocomplete
            options={wallets?.list}
            filterOptions={(x) => x}
            getOptionLabel={(option) => option.name || ""}
            value={selectedWallet}
            onChange={(event, newValue) => {
              setSelectedWallet(newValue);
              setError({
                ...error,
                wallet: ""
              });
            }}
            renderInput={(params) => <TextField {...params} label="Select Wallet" />}
          />
          {error.wallet && (
            <div className={styles.error}>
              <ErrorOutlineIcon className={styles.errIcon} />
              {error.wallet}
            </div>
          )}
        </div>

        <div>
          <TextField
            label="Name of the Store"
            value={storeName}
            onChange={(e) => {
              setStoreName(e.target.value);
              setError({
                ...error,
                store: ""
              });
            }}
            fullWidth
          />
          {error.store && (
            <div className={styles.error}>
              <ErrorOutlineIcon className={styles.errIcon} />
              {error.store}
            </div>
          )}
        </div>

        <div>
          <TextField
            label="Enter Cash Amount"
            value={cashAmount}
            onChange={(e) => {
              setCashAmount(e.target.value);
              setError({
                ...error,
                amount: ""
              });
            }}
            type="number"
            fullWidth
          />
          {error.amount && (
            <div className={styles.error}>
              <ErrorOutlineIcon className={styles.errIcon} />
              {error.amount}
            </div>
          )}
        </div>
      </main>
      <footer className={styles.buttons}>
        <Button variant="contained" className={styles.backBtn} onClick={handleBack}>
          Back
        </Button>
        <Button
          variant="contained"
          className={styles.continueBtn}
          onClick={handleConfirm}
          disabled={transactionLoading}
        >
          {transactionLoading ? <CircularProgress size={24} style={{ color: "white" }} /> : "Confirm"}
        </Button>
      </footer>
    </div>
  );
}
