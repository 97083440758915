import Swal from "sweetalert2";
import store from "src/page/store";
import { upgradePlanAction } from "src/page/auth/loginSlice";

interface ErrorConfig {
  title: string;
  message: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  showCancelButton?: boolean;
  onConfirm?: () => void;
}

export const errorMapping: Record<string, ErrorConfig> = {
  LIMIT_EXCEEDED: {
    title: "Limit Exceeded",
    message: "Your subscription plan limits the action. Please upgrade your plan to proceed.",
    confirmButtonText: "Upgrade Now",
    cancelButtonText: "Cancel",
    showCancelButton: true,
    onConfirm: () => {
      const dispatch = store.dispatch;
      const { auth } = store.getState();

      const upgradePlanData = {
        user_name: String(auth?.userInfo?.fullName),
        user_email: String(auth?.userInfo?.email),
        user_id: String(auth?.userInfo?.id),
        organisation_name: String(auth?.cognitoConfig?.currentOrganisation?.organisation_name),
        organisation_id: String(auth?.cognitoConfig?.currentOrganisation?.organisation_id)
      };

      dispatch(
        upgradePlanAction(upgradePlanData, () => {
          Swal.fire({
            icon: "success",
            title: "Thank you! Our team will contact you shortly to assist with upgrading your plan.",
            showConfirmButton: false,
            timer: 3000
          });
        })
      );
    }
  }
};

export const showErrorAlert = (errorType: string, fallbackMessage: string) => {
  const errorConfig = errorMapping[errorType];

  if (errorConfig) {
    Swal.fire({
      title: errorConfig.title,
      text: errorConfig.message,
      icon: "warning",
      showCancelButton: errorConfig.showCancelButton ?? false,
      confirmButtonText: errorConfig.confirmButtonText ?? "OK",
      cancelButtonText: errorConfig.cancelButtonText ?? "Cancel"
    }).then((result) => {
      if (result.isConfirmed && errorConfig.onConfirm) {
        errorConfig.onConfirm();
      }
    });
  } else {
    Swal.fire({
      title: "Error",
      text: fallbackMessage,
      icon: "error",
      confirmButtonText: "OK"
    });
  }
};
