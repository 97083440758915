import { ChangeEvent, useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import styles from "./addUserModal.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { roleMapping, reverseRoleMapping } from "src/components/constant/constant";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useAppSelector } from "src/page/store";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Autocomplete,
  TextField,
  CircularProgress
} from "@mui/material";
import { CreateUserRequest, checkEmailAvailability, checkUserNameAvailability } from "src/network/graphql/userService";
import { countryCodeData } from "src/types/countryCode";
import UserNameInput from "src/components/userNameInput/UserNameInput";
import { emailValidation, userNameValidation } from "src/utils/utils";
import { parsePhoneNumberFromString } from "libphonenumber-js";

interface AddUserModalProps {
  handleClose: () => void;
  onAddUser: (_addUser: CreateUserRequest, _close: boolean, _cb?: () => void) => void;
  rootContainer?: string;
  closeButton?: boolean;
  secondaryButton?: boolean;
  heading?: string;
  buttonText?: {
    primaryButton?: string;
    secondaryButton?: string;
  };
  role?: string;
  userloading?: boolean;
}
export default function AddUserModal({
  handleClose,
  onAddUser,
  rootContainer,
  closeButton = true,
  secondaryButton = true,
  heading = "Add User",
  buttonText,
  role,
  userloading = false
}: AddUserModalProps) {
  const { cognitoConfig } = useAppSelector((state) => state.auth);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const [input, setInput] = useState({
    initial: "Mr.",
    firstName: "",
    lastName: "",
    dob: "",
    email: "",
    gender: "male",
    phoneCountryCode: "+61",
    phone: "",
    role: "",
    line1: "",
    line2: "",
    city: "",
    state: "",
    postcode: "",
    country: "Australia",
    userName: ""
  });

  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    dob: "",
    email: "",
    phone: "",
    role: "",
    line1: "",
    city: "",
    state: "",
    postcode: "",
    country: "",
    userName: ""
  });

  const handleCancelClose = () => {
    handleClose();
  };

  useEffect(() => {
    if (role) {
      setInput((prevInput) => ({
        ...prevInput,
        role
      }));
    }
  }, [role]);

  const handleInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    setInput({
      ...input,
      [event.target.name]: event.target.value
    });
  };

  const handleCancel = async () => {
    if (
      !input.email?.trim() ||
      !input.firstName?.trim() ||
      !input.lastName?.trim() ||
      !input.role?.trim() ||
      !input.dob?.trim() ||
      !input.city?.trim() ||
      !input.state?.trim() ||
      !input.postcode?.trim() ||
      !input.country?.trim() ||
      !input.phone?.trim() ||
      !input.line1?.trim()
    ) {
      setError({
        email: input.email ? (emailValidation(input.email.trim()) ? "" : "Invalid email") : "Email is required",
        firstName: input.firstName ? "" : "First name is required",
        lastName: input.lastName ? "" : "Last name is required",
        role: input.role ? "" : "Role is required",
        dob: input.dob ? "" : "DOB is required",
        // eslint-disable-next-line no-nested-ternary
        phone: input.phone
          ? parsePhoneNumberFromString(input.phoneCountryCode + input.phone)?.isValid()
            ? ""
            : "Invalid contact number"
          : "Phone is required",
        line1: input.line1 ? "" : "Line1 is required",
        city: input.city ? "" : "City is required",
        state: input.state ? "" : "State is required",
        postcode: input.postcode ? "" : "Postcode is required",
        country: input.country ? "" : "Country is required",
        // eslint-disable-next-line no-nested-ternary
        userName: input.userName ? (userNameValidation(input.userName) ? error.userName : "") : "Username is required"
      });
      return;
    }

    const trimmedEmail = input.email.trim().toLowerCase();
    const trimmedUserName = input.userName.trim().toLowerCase();

    setSubmitLoading(true);

    const isUserNameAvailable = await checkUserNameAvailability(trimmedUserName);

    if (!isUserNameAvailable.data.is_username_available) {
      setError({
        ...error,
        userName: "username already exists"
      });
      return;
    }
    const isEmailAvailable = await checkEmailAvailability(trimmedEmail);

    if (!isEmailAvailable.data.is_email_available) {
      setError({
        ...error,
        email: "email already exists"
      });
      return;
    }

    onAddUser(
      {
        ...input,
        email: trimmedEmail,
        userName: trimmedUserName,
        organisationId: cognitoConfig.currentOrganisation?.organisation_id,
        phone: input.phoneCountryCode + input.phone
      } as CreateUserRequest,
      true,
      () => {
        setSubmitLoading(false);
      }
    );
  };

  const handleContactBlur = () => {
    const phoneNumber = parsePhoneNumberFromString(input.phoneCountryCode + input.phone);

    if (!phoneNumber || !phoneNumber.isValid()) {
      setError({
        ...error,
        phone: "Invalid contact number"
      });
    } else {
      setError({
        ...error,
        phone: ""
      });
    }
  };

  const handleEmailValidation = () => {
    const trimmedEmail = input.email.trim().toLowerCase();

    if (!emailValidation(trimmedEmail)) {
      setError({
        ...error,
        email: "Invalid email"
      });
    } else {
      setError({
        ...error,
        email: ""
      });
    }
  };

  const handleAddUser = async () => {
    if (
      !input.email.trim() ||
      !input.firstName.trim() ||
      !input.lastName.trim() ||
      !input.role.trim() ||
      !input.dob.trim() ||
      !input.city.trim() ||
      !input.state.trim() ||
      !input.postcode.trim() ||
      !input.country.trim() ||
      !input.phone.trim() ||
      !input.line1.trim()
    ) {
      setError({
        email: input.email ? (emailValidation(input.email.trim()) ? "" : "Invalid email") : "Email is required",
        firstName: input.firstName ? "" : "First name is required",
        lastName: input.lastName ? "" : "Last name is required",
        role: input.role ? "" : "Role is required",
        dob: input.dob ? "" : "DOB is required",
        // eslint-disable-next-line no-nested-ternary
        phone: input.phone
          ? parsePhoneNumberFromString(input.phoneCountryCode + input.phone)?.isValid()
            ? ""
            : "Invalid contact number"
          : "Phone is required",
        line1: input.line1 ? "" : "Line1 is required",
        city: input.city ? "" : "City is required",
        state: input.state ? "" : "State is required",
        postcode: input.postcode ? "" : "Postcode is required",
        country: input.country ? "" : "Country is required",
        // eslint-disable-next-line no-nested-ternary
        userName: input.userName ? (userNameValidation(input.userName) ? error.userName : "") : "Username is required"
      });
      return;
    }

    const trimmedEmail = input.email.trim().toLowerCase();
    const trimmedUserName = input.userName.trim().toLowerCase();

    setSubmitLoading(true);

    const isUserNameAvailable = await checkUserNameAvailability(trimmedUserName);

    if (!isUserNameAvailable.data.is_username_available) {
      setSubmitLoading(false);
      setError({
        ...error,
        userName: "username already exists"
      });
      return;
    }
    const isEmailAvailable = await checkEmailAvailability(trimmedEmail);

    if (!isEmailAvailable.data.is_email_available) {
      setSubmitLoading(false);
      setError({
        ...error,
        email: "email already exists"
      });
      return;
    }

    onAddUser(
      {
        ...input,
        email: trimmedEmail,
        userName: trimmedUserName,
        organisationId: cognitoConfig.currentOrganisation?.organisation_id,
        phone: input.phoneCountryCode + input.phone
      } as CreateUserRequest,
      false,
      () => {
        setSubmitLoading(false);
        setInput({
          initial: "Mr.",
          firstName: "",
          lastName: "",
          dob: "",
          email: "",
          gender: "Male",
          phoneCountryCode: "+61",
          phone: "",
          role: "",
          line1: "",
          line2: "",
          city: "",
          state: "",
          postcode: "",
          country: "Australia",
          userName: ""
        });
        setError({
          email: "",
          firstName: "",
          lastName: "",
          role: "",
          dob: "",
          phone: "",
          line1: "",
          city: "",
          state: "",
          postcode: "",
          country: "",
          userName: ""
        });
      }
    );
  };

  const handleParticipantChange = (event: React.ChangeEvent<object>, value: string | null) => {
    setInput({
      ...input,
      role: value ? reverseRoleMapping[value] : "Participant"
    });
  };

  const handleCountryCodeChange = (event: SelectChangeEvent<string>) => {
    setInput({ ...input, [event.target.value]: event.target.value });
  };

  return (
    <div className={`${styles.referModalBox} ${rootContainer}`}>
      <div className={styles.referModalTop}>
        <form>
          <div className={styles.close}>
            <h5>{heading}</h5>
            {closeButton && (
              <button onClick={handleCancelClose} aria-label="Close">
                <CloseIcon onClick={handleCancelClose} />
              </button>
            )}
          </div>
          {userloading ? (
            <div className={styles.progress}>
              <CircularProgress sx={{ color: "#0f172a" }} />
            </div>
          ) : (
            <>
              <div className={styles.newUser}>
                <h3>Personal Information</h3>
                <div className={styles.name}>
                  <div className={styles.initial}>
                    <FormControl sx={{ m: 1, margin: 0 }}>
                      <Select
                        value={input.initial}
                        onChange={handleSelectChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label", name: "initial" }}
                        sx={{
                          height: 49
                        }}
                      >
                        <MenuItem value="Mr.">Mr.</MenuItem>
                        <MenuItem value="Mrs.">Mrs.</MenuItem>
                        <MenuItem value="Mast.">Mast.</MenuItem>
                        <MenuItem value="Miss.">Miss.</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className={styles.Fname}>
                    <label>First Name</label>
                    <input
                      type="text"
                      placeholder="Enter Text Here"
                      value={input.firstName}
                      name="firstName"
                      onChange={handleInput}
                      aria-label="First Name"
                    />
                    {error.firstName && (
                      <div className={styles.error}>
                        <ErrorOutlineIcon className={styles.errIcon} />
                        {error.firstName}
                      </div>
                    )}
                  </div>
                  <div className={styles.Lname}>
                    <label>Last Name</label>
                    <input
                      type="text"
                      placeholder="Enter Text Here"
                      value={input.lastName}
                      name="lastName"
                      onChange={handleInput}
                      aria-label="Last Name"
                    />
                    {error.lastName && (
                      <div className={styles.error}>
                        <ErrorOutlineIcon className={styles.errIcon} />
                        {error.lastName}
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.userrow}>
                  <div className={styles.dob}>
                    <label>DOB:</label>
                    <input type="date" value={input.dob} name="dob" onChange={handleInput} />
                    {error.dob && (
                      <div className={styles.error}>
                        <ErrorOutlineIcon className={styles.errIcon} />
                        {error.dob}
                      </div>
                    )}
                  </div>
                  <div className={styles.email}>
                    <label>User Email</label>
                    <input
                      type="email"
                      placeholder="example@gmail.com"
                      value={input.email}
                      name="email"
                      onChange={handleInput}
                      onBlur={handleEmailValidation}
                      aria-label="Email"
                    />
                    {error.email && (
                      <div className={styles.error}>
                        <ErrorOutlineIcon className={styles.errIcon} />
                        {error.email}
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.userrow}>
                  <div className={styles.gender}>
                    <label>Gender:</label>
                    <FormControl sx={{ m: 1, margin: 0 }}>
                      <Select
                        value={input.gender}
                        onChange={handleSelectChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label", name: "gender" }}
                        sx={{
                          height: 49
                        }}
                      >
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="female">Female</MenuItem>
                        <MenuItem value="unknown">Non Binary</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className={styles.phone}>
                    <label>Contact</label>
                    <div className={styles.mobileSelect}>
                      <Select
                        value={input.phoneCountryCode || "+61"}
                        onChange={handleCountryCodeChange}
                        displayEmpty
                        name="phoneCountryCode"
                        className={styles.countryCodeSelect}
                        sx={{ height: "50px" }}
                      >
                        {countryCodeData.map((country) => (
                          <MenuItem key={country.code} value={country.code}>
                            {country.code}
                          </MenuItem>
                        ))}
                      </Select>
                      <input
                        type="text"
                        placeholder="4745625522"
                        value={input.phone}
                        name="phone"
                        onChange={handleInput}
                        onBlur={handleContactBlur}
                        aria-label="Phone Number"
                      />
                    </div>
                    {error.phone && (
                      <div className={styles.error}>
                        <ErrorOutlineIcon className={styles.errIcon} />
                        {error.phone}
                      </div>
                    )}
                  </div>
                </div>
                <h3>Address</h3>
                <div className={styles.userrow}>
                  <div className={styles.addressLine1}>
                    <label>Line 1 :</label>
                    <div className={styles.inputWerror}>
                      <input
                        type="text"
                        placeholder="Enter Text Here"
                        value={input.line1}
                        name="line1"
                        onChange={handleInput}
                      />
                      {error.line1 && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {error.line1}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.addressLine2}>
                    <label>Line 2 :</label>
                    <input
                      type="text"
                      placeholder="Enter Text Here"
                      value={input.line2}
                      name="line2"
                      onChange={handleInput}
                      aria-label="Address"
                    />
                  </div>
                </div>
                <div className={styles.userrow}>
                  <div className={styles.city}>
                    <label>City :</label>
                    <div className={styles.inputWerror}>
                      <input
                        type="text"
                        placeholder="Enter Text Here"
                        value={input.city}
                        name="city"
                        onChange={handleInput}
                      />
                      {error.city && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {error.city}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.state}>
                    <label>State :</label>
                    <div className={styles.inputWerror}>
                      <input
                        type="text"
                        placeholder="Enter Text Here"
                        value={input.state}
                        name="state"
                        onChange={handleInput}
                        aria-label="State"
                      />
                      {error.state && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {error.state}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.userrow}>
                  <div className={styles.postcode}>
                    <label>Postcode :</label>
                    <div className={styles.inputWerror}>
                      <input
                        type="text"
                        placeholder="Enter Text Here"
                        value={input.postcode}
                        name="postcode"
                        onChange={handleInput}
                        aria-label="PostCode"
                      />
                      {error.postcode && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {error.postcode}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.country}>
                    <label>Country :</label>
                    <div className={styles.inputWerror}>
                      <input
                        type="text"
                        placeholder="Enter Text Here"
                        value={input.country}
                        name="country"
                        // onChange={handleInput}
                        disabled
                      />
                      {error.country && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {error.country}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {!role && (
                <div className={styles.Role}>
                  <label>Role</label>
                  <Autocomplete
                    className={styles.autoComplete}
                    options={cognitoConfig?.rolesAllowedToAdd?.map((roles: any) => roleMapping[roles]) || []}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => <TextField {...params} placeholder="Select your Role.." />}
                    value={roleMapping[input.role]}
                    onChange={handleParticipantChange}
                    filterOptions={(x) => x}
                  />
                  {error.role && (
                    <div className={styles.error}>
                      <ErrorOutlineIcon className={styles.errIcon} />
                      {error.role}
                    </div>
                  )}
                </div>
              )}
              <UserNameInput
                error={error.userName}
                setError={(value: string) => {
                  setError({
                    ...error,
                    userName: value
                  });
                }}
                value={input.userName}
                setValue={(value) => {
                  setInput((prevInput) => ({
                    ...prevInput,
                    userName: value
                  }));
                }}
              />
            </>
          )}
        </form>
      </div>
      <div className={styles.referModalBottom}>
        <div className={styles.referModalBtn}>
          <Stack spacing={2} direction="row">
            {secondaryButton && (
              <Button
                className={styles.cancelBtn}
                variant="text"
                onClick={handleCancel}
                disabled={submitLoading || userloading}
              >
                {submitLoading || userloading ? (
                  <CircularProgress sx={{ color: "white" }} />
                ) : (
                  buttonText?.secondaryButton || "Add & Back To List"
                )}
              </Button>
            )}
            <Button
              className={styles.sendBtn}
              variant="contained"
              onClick={handleAddUser}
              disabled={submitLoading || userloading}
            >
              {submitLoading || userloading ? (
                <CircularProgress sx={{ color: "white" }} />
              ) : (
                buttonText?.primaryButton || "Add & another User"
              )}
            </Button>
          </Stack>
        </div>
      </div>
    </div>
  );
}
