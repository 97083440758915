import { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import styles from "./internalFilterTransaction.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete, FormControl, MenuItem, Select, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "src/page/store";
import { useParams } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { resetTransactionFilters, setTransactionFilter } from "src/page/filtersSlice";
import { User } from "src/types/commonTypes";
import { debounce } from "lodash";
import { Wallet } from "src/page/wallets/walletSlice";
import { utc } from "moment";
import {
  fetchUserListAction,
  fetchWalletListAction,
  setUsers,
  setWallets
} from "src/page/internalTransactions/InternalTransactionSlice";

interface FilterTransModalProps {
  handleClose: () => void;
}

export default function FilterTransactionModal({ handleClose }: FilterTransModalProps) {
  const [error, setError] = useState({ Amt: "", Date: "" });
  const { walletId, userId } = useParams();
  const { users, walletList } = useAppSelector((state) => state.transaction);
  const { transaction: transactionFilters } = useAppSelector((state) => state.filters);

  const [selectedWallet, setSelectedWallet] = useState<Wallet[]>(transactionFilters.wallet || []);
  const [selectedUser, setSelectedUser] = useState<User[]>(transactionFilters.user || []);
  const [activityName, setActivityName] = useState<string | null>(transactionFilters.activityName);
  const [startDate, setStartDate] = useState<string | null>(transactionFilters.startDate);
  const [endDate, setEndDate] = useState<string | null>(transactionFilters.startDate);
  const [minAmt, setMinAmt] = useState<string | null>(transactionFilters.minAmount);
  const [maxAmt, setMaxAmt] = useState<string | null>(transactionFilters.maxAmount);
  const dispatch = useAppDispatch();

  const handleCancel = () => {
    handleClose();
    dispatch(setUsers([]));
    dispatch(setWallets([]));
  };

  const handleApplyFilter = () => {
    if (Number(minAmt) > Number(maxAmt) && maxAmt !== "") {
      setError({
        ...error,
        Amt: "Maximum amount should always be larger than minimum amount"
      });
      return;
    }

    if (startDate && !endDate) {
      setError({
        ...error,
        Date: "Please make sure to select the end date as well"
      });
      return;
    }
    if (endDate && !startDate) {
      setError({
        ...error,
        Date: "Please make sure to select the start date as well"
      });
      return;
    }
    if (startDate && endDate && new Date(startDate) > new Date(endDate) && endDate !== "") {
      setError({
        ...error,
        Date: "End date should always be larger than start date"
      });
      return;
    }
    if (endDate && startDate && utc(endDate).diff(utc(startDate), "days") > 90) {
      setError({
        ...error,
        Date: "You can only set 90 days of range"
      });
      return;
    }

    dispatch(
      setTransactionFilter({
        user: selectedUser,
        wallet: selectedWallet,
        activityName: activityName as string,
        startDate,
        endDate,
        minAmount: minAmt,
        maxAmount: maxAmt
      })
    );
    handleCancel();
  };

  const handleReset = () => {
    dispatch(resetTransactionFilters());
    setSelectedUser([]);
    setSelectedWallet([]);
    setActivityName(null);
    setStartDate(null);
    setEndDate(null);
    setMinAmt(null);
    setMaxAmt(null);
    setError({ Amt: "", Date: "" });
    handleCancel();
  };

  const handleWalletInput = debounce((event: React.ChangeEvent<object>, input: string) => {
    if (input.length < 2) return;
    const data = {
      size: 10,
      from: 0,
      walletFilter: {
        name: input
      }
    };

    dispatch(fetchWalletListAction(data));
  }, 500);

  const handleUserInput = debounce((event: React.ChangeEvent<object>, input: string) => {
    if (input.length < 2) return;
    const data = {
      user_filter: {
        full_name: input
      },
      size: 15,
      from: 0
    };

    dispatch(fetchUserListAction(data));
  }, 500);

  useEffect(() => {
    if (Number(minAmt) > Number(maxAmt) && maxAmt !== "") {
      setError({
        ...error,
        Amt: "Maximum amount should always be larger than minimum amount"
      });
    } else {
      setError({
        ...error,
        Amt: ""
      });
    }
  }, [minAmt, maxAmt, error]);

  useEffect(() => {
    if (startDate && !endDate) {
      setError({
        ...error,
        Date: "Please make sure to select the end date as well"
      });
      return;
    }
    if (endDate && !startDate) {
      setError({
        ...error,
        Date: "Please make sure to select the start date as well"
      });
      return;
    }
    if (startDate && endDate && new Date(startDate) > new Date(endDate) && endDate !== "") {
      setError({
        ...error,
        Date: "End date should always be larger than start date"
      });
    } else if (endDate && startDate && utc(endDate).diff(utc(startDate), "days") > 90) {
      setError({
        ...error,
        Date: "You can only set 90 days of range"
      });
      return;
    } else {
      setError({
        ...error,
        Date: ""
      });
    }
  }, [startDate, endDate, error]);

  return (
    <div className={styles.referModalBox}>
      <div className={styles.referModalTop}>
        <form>
          <div className={styles.close}>
            <h5>Filter Transactions</h5>
            <button onClick={handleCancel}>
              <CloseIcon onClick={handleCancel} />
            </button>
          </div>

          <div className={styles.filterByDate}>
            <div className={styles.head}>Filter by Date</div>
            <div className={styles.date}>
              <div className={styles.startDate}>
                <label>Start Date</label>
                <TextField
                  type="date"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </div>
              <div className={styles.endDate}>
                <label>End Date</label>
                <TextField
                  type="date"
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                    classes: {
                      root: "MuiInputLabel-outlined"
                    }
                  }}
                />
              </div>
            </div>
            {error.Date && (
              <div className={styles.error}>
                <ErrorOutlineIcon className={styles.errIcon} />
                {error.Date}
              </div>
            )}
          </div>
          <div className={styles.addUser}>
            {!walletId && (
              <div className={styles.byWallet}>
                <label>By Wallet</label>

                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  options={walletList?.list || []}
                  filterOptions={(x) => x}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option?.name ?? option}
                  onChange={(event, value) => {
                    setSelectedWallet(value);
                  }}
                  onInputChange={handleWalletInput}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderOption={(props, option) => (
                    <li
                      {...props}
                      key={option.id}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start"
                      }}
                    >
                      <span>{option?.name}</span>
                      <span style={{ color: "#64748B", fontSize: "14px" }}>{option?.participant?.email}</span>
                    </li>
                  )}
                  renderInput={(params) => <TextField {...params} placeholder="Wallets...." />}
                  value={selectedWallet}
                />
              </div>
            )}
            {!userId && (
              <div className={styles.byUser}>
                <div className={styles.byUserHead}>
                  <label>By User</label>
                </div>
                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  options={users?.list || []}
                  filterOptions={(x) => x}
                  disableCloseOnSelect
                  getOptionLabel={(option: any) => option?.firstName ?? option}
                  onInputChange={handleUserInput}
                  value={selectedUser}
                  onChange={(event, newValue) => {
                    setSelectedUser(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} placeholder="Users...." />}
                  renderOption={(props, option) => (
                    <li
                      {...props}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start"
                      }}
                    >
                      <span>{option?.firstName + " " + option?.lastName}</span>
                      <span style={{ color: "#64748B" }}>{option?.email}</span>
                    </li>
                  )}
                />
              </div>
            )}
          </div>
          <div className={styles.categoryFilter}>
            <div className={styles.byCategory}>
              <div className={styles.head}>By Activity Name</div>

              <FormControl>
                <Select
                  value={activityName || ""}
                  onChange={(e) => {
                    setActivityName(e.target.value);
                  }}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    height: 65,
                    color: {
                      NOVATTI_CARD_TRANSACTION: "",
                      WALLET_TRANSACTION: "",
                      default: "grey"
                    }[activityName ?? "default"]
                  }}
                >
                  <MenuItem value="" sx={{ color: "grey" }}>
                    Activity Name
                  </MenuItem>
                  <MenuItem value="NOVATTI_CARD_TRANSACTION">Novatti Transaction</MenuItem>
                  <MenuItem value="WALLET_TRANSACTION">Wallet Transaction</MenuItem>
                  <MenuItem value="CASH_TRANSACTION">Cash Transaction</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          {/* <div className={styles.filterByAmt}>
            <div className={styles.head}>Filter by Amount</div>
            <div className={styles.amt}>
              <div className={styles.min}>
                <label>Minimum</label>
                <input
                  type="number"
                  placeholder="$0"
                  value={minAmt ? Number(minAmt) : undefined}
                  onChange={(e) => {
                    setMinAmt(e.target.value);
                  }}
                />
              </div>
              <div className={styles.max}>
                <label>Maximum</label>
                <input
                  type="number"
                  placeholder="$0"
                  value={maxAmt ? Number(maxAmt) : undefined}
                  onChange={(e) => {
                    setMaxAmt(e.target.value);
                  }}
                />
              </div>
            </div>
            {error.Amt && (
              <div className={styles.error}>
                <ErrorOutlineIcon className={styles.errIcon} />
                {error.Amt}
              </div>
            )}
          </div> */}
        </form>
      </div>
      <div className={styles.referModalBottom}>
        <div className={styles.referModalBtn}>
          <Stack spacing={2} direction="row">
            <Button className={styles.cancelBtn} variant="text" onClick={handleReset}>
              Reset Filter
            </Button>
            <Button className={styles.sendBtn} variant="contained" onClick={handleApplyFilter}>
              Apply Filter
            </Button>
          </Stack>
        </div>
      </div>
    </div>
  );
}
