import client from "../client";
import internalClient from "../internalClient";

export const getAllOrganisations = (data: { active: boolean; limit: number }) => {
  return client({
    query: `
      query GetOrganisations {
        get_all_organisations(is_active: ${data.active}, limit: ${data.limit}) {
          organisation {
            display_id
            organisation_id
            organisation_status
            name
            contact {
              email
              phone
              role
              name
            }
            is_active
            created_at
          }
        }
      }
    `
  });
};

export const getOrganisationDetail = (organisationId: string | number) => {
  return client({
    query: `
    query GetOrganisation($input: GetOrganisationInput) {
      get_organisation(input: $input){
        display_id
        organisation_id
        organisation_status
        name
        abn
        phone
        type
        address {
          city
          line_1
          line_2
          post_code
          country
          state
        }
        contact {
          email
          phone
          role
          name
        }
        social {
          website
          facebook
          instagram
          linkedin
        }
        is_active
        created_at
      }
    }
    `,
    variables: {
      input: {
        organisation_id: organisationId
      }
    }
  });
};

export type OrganisationFilterType = {
  active?: boolean | null;
  startDate?: string | null;
  endDate?: string | null;
  userIds?: number[];
  displayId?: string | null;
  searchName?: string | null;
  type?: string;
  limit: number;
  from: number;
};

export const organisationFilter = (data: OrganisationFilterType) => {
  return client({
    query: `
    query MyQuery ($input: SearchInput) {
      search_organisation(input:$input) {
            organisation_listing {
              name
              type
              is_active
              display_id
              abn
              address {
                city
                country
                line_1
                line_2
                post_code
                state
            }
            contact {
                email
                name
                phone
                role
            }
            created_at
            organisation_id
            organisation_status
            phone
            social {
                facebook
                instagram
                linkedin
                snapchat
                website
                tiktok
                wechat
                whatsapp
            }
            updated_at
        }
          total_size
          total_pages
          }
        }`,
    variables: {
      input: {
        filter: {
          organisation_filter: {
            is_active: data.active === null ? undefined : data.active,
            name: data.searchName,
            type: data.type,
            display_id: data.displayId
          }
        },
        size: data.limit,
        from: data.from
      }
    }
  });
};

export const internalOrganisationFilter = (data: OrganisationFilterType) => {
  return internalClient({
    query: `
    query MyQuery ($input: SearchInput) {
      search_organisation(input:$input) {
            organisation_listing {
              name
              type
              is_active
              display_id
              abn
              address {
                city
                country
                line_1
                line_2
                post_code
                state
            }
            contact {
                email
                name
                phone
                role
            }
            created_at
            organisation_id
            organisation_status
            phone
            social {
                facebook
                instagram
                linkedin
                snapchat
                website
                tiktok
                wechat
                whatsapp
            }
            updated_at
        }
          total_size
          total_pages
          }
        }`,
    variables: {
      input: {
        filter: {
          organisation_filter: {
            is_active: data.active === null ? undefined : data.active,
            name: data.searchName,
            type: data.type,
            display_id: data.displayId
          }
        },
        size: data.limit,
        from: data.from
      }
    }
  });
};

export interface AddOrgRequest {
  organisationId?: number | string;
  id: number | null;
  name: string;
  abn: string;
  address: string;
  phone: string;
  type: string;
  countryCode?: string;
  website?: string;
  facebook?: string;
  linkedIn?: string;
  instagram?: string;
  referenceName: string;
  referenceRole: string;
  referenceCountryCode?: string;
  referencePhone: string;
  referenceEmail: string;
  createdBy?: string;
  createdOn?: string;
  updatedBy?: string;
  updatedOn?: string;
  active?: boolean;
  line1?: string;
  line2?: string;
  city?: string;
  state?: string;
  postcode?: string;
  country?: string;
  superAdminInitial?: string;
  superAdminFirstName?: string;
  superAdminLastName?: string;
  superAdminDob?: string;
  superAdminEmail?: string;
  superAdminGender?: string;
  superAdminPhone?: string;
  superAdminaddressLine1?: string;
  superAdminaddressLine2?: string;
  superAdminCity?: string;
  superAdminState?: string;
  superAdminPostcode?: string;
  superAdminCountry?: string;
  superAdminUserName?: string;
  existingUserEmail?: string;
}
export const createOrganisation = (data: AddOrgRequest) => {
  return client({
    query: `
      mutation create_organisation($input: CreateOrganisationInput) {
        create_organisation(input: $input) {
          organisation_id
        }
      }
    `,
    variables: {
      input: {
        name: data.name,
        abn: data.abn,
        address: {
          city: data.city,
          country: data.country,
          line_1: data.line1,
          line_2: data.line2,
          post_code: data.postcode,
          state: data.state
        },
        phone: data.phone,
        type: data.type,
        contact: {
          name: data.referenceName,
          role: data.referenceRole,
          phone: data.referencePhone,
          email: data.referenceEmail
        },
        social: {
          website: data.website,
          linkedin: data.linkedIn,
          facebook: data.facebook,
          instagram: data.instagram
        },
        super_admin_user: {
          address: {
            city: data.superAdminCity,
            country: data.superAdminCountry,
            line_1: data.superAdminaddressLine1,
            line_2: data.superAdminaddressLine2,
            post_code: data.superAdminPostcode,
            state: data.superAdminState
          },
          date_of_birth: data.superAdminDob,
          email: data.superAdminEmail,
          first_name: data.superAdminFirstName,
          last_name: data.superAdminLastName,
          gender: data.superAdminGender,
          initial: data.superAdminInitial,
          phone: data.superAdminPhone,
          username: data.superAdminUserName
        }
      }
    }
  });
};

export const createInitialOrganisation = (data: AddOrgRequest) => {
  return client({
    query: `
      mutation create_organisation_accessible($input: CreateOrganisationInput) {
        create_organisation_accessible(input: $input) {
          organisation_id
        }
      }
    `,
    variables: {
      input: {
        name: data.name,
        abn: data.abn,
        address: {
          city: data.city,
          country: data.country,
          line_1: data.line1,
          line_2: data.line2,
          post_code: data.postcode,
          state: data.state
        },
        phone: data.phone,
        type: data.type,
        contact: {
          name: data.referenceName,
          role: data.referenceRole,
          phone: data.referencePhone,
          email: data.referenceEmail
        },
        social: {
          website: data.facebook,
          linkedin: data.linkedIn,
          facebook: data.facebook,
          instagram: data.instagram
        },
        super_admin_user: {
          address: {
            city: data.superAdminCity,
            country: data.superAdminCountry,
            line_1: data.superAdminaddressLine1,
            line_2: data.superAdminaddressLine2,
            post_code: data.superAdminPostcode,
            state: data.superAdminState
          },
          date_of_birth: data.superAdminDob,
          email: data.superAdminEmail,
          first_name: data.superAdminFirstName,
          last_name: data.superAdminLastName,
          gender: data.superAdminGender,
          initial: data.superAdminInitial,
          phone: data.superAdminPhone,
          username: data.superAdminUserName
        }
      }
    }
  });
};

export const updateOrganisation = (data: AddOrgRequest) => {
  return client({
    query: ` mutation updateOrganisation ($input: UpdateOrganisationInput) {
      update_organisation(input: $input) {
        organisation_id
      }
    }`,
    variables: {
      input: {
        organisation_id: data.organisationId,
        name: data.name,
        abn: data.abn,
        address: {
          city: data.city,
          country: data.country,
          line_1: data.line1,
          line_2: data.line2,
          post_code: data.postcode,
          state: data.state
        },
        phone: data.phone,
        contact: {
          name: data.referenceName,
          role: data.referenceRole,
          phone: data.referencePhone,
          email: data.referenceEmail.toLowerCase()
        },
        social: {
          website: data.website,
          facebook: data.facebook,
          instagram: data.instagram,
          linkedin: data.linkedIn
        }
      }
    }
  });
};

export const toggleOrganisationActivation = (data: { isActive: boolean; organisationId: string }) => {
  return client({
    query: `
    mutation ToggleOrganisationActivation($input: ToggleOrganisationActivationInput) {
      toggle_organisation_activation(input: $input) {
        organisation_id
      }
    }
    `,
    variables: {
      input: {
        organisation_id: data.organisationId,
        is_active: data.isActive
      }
    }
  });
};
