import { useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import styles from "./attachCard.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "src/page/store";
import { getAllCardsAction } from "src/page/walletDetail/walletDetailSlice";
import { debounce } from "lodash";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
interface Iprops {
  handleClose: () => void;
  participantName: string;
  organisationId?: number;
  onAddSupporter: (_cardNumber: string, _cardId: string, _close: boolean, _connectedToParticipant: boolean) => void;
}

export default function AttachCard({ handleClose, participantName, onAddSupporter }: Iprops) {
  const [cardNumber, setCardNumber] = useState<string>("");
  const [cardId, setCardId] = useState<string>("");
  const [connectToParticipant, setConnectToParticipant] = useState<boolean>(false);
  const [error, setError] = useState({ cardNumber: "" });

  const { cards } = useAppSelector((state) => state.walletDetail);
  const dispatch = useAppDispatch();

  const handleAddAndClose = () => {
    if (!cardNumber.trim()) {
      setError({
        cardNumber: cardNumber ? "" : "User email is required"
      });
      return;
    }
    onAddSupporter(cardNumber, cardId, true, connectToParticipant);
  };

  const handleAddAndStay = () => {
    if (!cardNumber.trim()) {
      setError({
        cardNumber: cardNumber ? "" : "User email is required"
      });
      return;
    }
    onAddSupporter(cardNumber, cardId, false, connectToParticipant);
  };

  const handleCancelClose = () => {
    handleClose();
  };

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConnectToParticipant(e.target.value === "Yes");
  };

  const handleInputChange = debounce((event: React.ChangeEvent<object>, input: string) => {
    const data = {
      card_filter: {
        ppan: "*" + input + "*"
      },
      size: 15,
      from: 0
    };

    dispatch(getAllCardsAction(data));
  }, 500);

  return (
    <div className={styles.referModalBox}>
      <main className={styles.referModalTop}>
        <form>
          <div className={styles.close}>
            <h5>Connect Card to {participantName}</h5>
            <button onClick={handleCancelClose} aria-label="close">
              <CloseIcon />
            </button>
          </div>

          <div className={styles.email}>
            <label htmlFor="card">Card</label>
            <Autocomplete
              id="card"
              options={cards.list}
              filterOptions={(x) => x}
              getOptionLabel={(option) => `${option?.cardNumber} ${option?.user?.fullName}`}
              style={{ width: 300 }}
              onInputChange={handleInputChange}
              onChange={(event, newValue) => {
                setCardId(newValue?.id + "" || "");
                setCardNumber(newValue?.cardNumber || "");
              }}
              renderInput={(params) => <TextField {...params} variant="outlined" />}
              renderOption={(props, option) => (
                <li
                  {...props}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start"
                  }}
                >
                  <span>{option?.user?.fullName}</span>
                  <span>{option?.cardNumber ? "xxxx-xxxx-xxxx-" + option?.cardNumber?.slice(-4) : "---"}</span>
                </li>
              )}
              ListboxProps={{ style: { maxHeight: "200px", overflow: "auto" } }}
            />
            {error.cardNumber && (
              <div className={styles.error}>
                <ErrorOutlineIcon className={styles.errIcon} />
                {error.cardNumber}
              </div>
            )}
          </div>
          <div className={styles.RadioInput}>
            <label>Connect to all of this participant’s wallet?</label>
            <div className={styles.inputs}>
              <div className={styles.inputYes}>
                <input type="radio" name="connectOption" value="Yes" id="yes" onChange={handleRadioChange} />
                <label htmlFor="yes">Yes</label>
              </div>
              <div className={styles.inputNo}>
                <input type="radio" name="connectOption" value="No" id="No" onChange={handleRadioChange} />
                <label htmlFor="No">No</label>
              </div>
            </div>
          </div>
        </form>
      </main>
      <footer className={styles.referModalBottom}>
        <div className={styles.referModalBtn}>
          <Stack spacing={2} direction="row">
            <Button className={styles.cancelBtn} variant="text" onClick={handleAddAndClose}>
              Connect & Back To List
            </Button>
            <Button className={styles.sendBtn} variant="contained" onClick={handleAddAndStay}>
              Connect & Add Another Card
            </Button>
          </Stack>
        </div>
      </footer>
    </div>
  );
}
