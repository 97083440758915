import { useState, useEffect } from "react";
import styles from "./userInfoCard.module.scss";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CloseIcon from "@mui/icons-material/Close";
import Modalui from "src/components/UI/modal/Modalui";
import UserInfoEdiModal from "./userInfoEditModal/UserInfoEditModal";
// import FormGroup from "@mui/material/FormGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Switch, { SwitchProps } from "@mui/material/Switch";
// import { styled } from "@mui/material/styles";
import { useAppDispatch, useAppSelector } from "src/page/store";
import {
  UserDetail,
  fetchUserDetailAction,
  resetPasswordAction,
  // updateUserDetailAction,
  updateUserRoleAction
} from "src/page/userDetail/userDetailSlice";
import {
  CAN_EDIT_USER_INFORMATION,
  CAN_UPDATE_USER_KYC,
  CAN_UPDATE_USER_ROLE,
  reverseRoleMapping,
  roleMapping,
  CAN_RESET_PASSWORD
} from "../constant/constant";
import { Link } from "react-router-dom";
import { Autocomplete, Button, MenuItem, Select, TextField, Menu, IconButton, CircularProgress } from "@mui/material";
import { checkPermission, getCurrentRole } from "src/utils/utils";
import { updateKYC } from "src/network/graphql/userService";
import { kycStatusType } from "src/types/commonTypes";

// const IOSSwitch = styled((props: SwitchProps & { checked?: boolean }) => (
//   <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
// ))(({ theme }) => ({
//   width: 35,
//   height: 20,
//   padding: 0,
//   "& .MuiSwitch-switchBase": {
//     padding: 0,
//     margin: 2,
//     transitionDuration: "300ms",
//     "&.Mui-checked": {
//       transform: "translateX(16px)",
//       color: "#fff",
//       "& + .MuiSwitch-track": {
//         backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#0f172a",
//         opacity: 1,
//         border: 0
//       },
//       "&.Mui-disabled + .MuiSwitch-track": {
//         opacity: 0.5
//       }
//     },
//     "&.Mui-focusVisible .MuiSwitch-thumb": {
//       color: "#33cf4d",
//       border: "6px solid #fff"
//     },
//     "&.Mui-disabled .MuiSwitch-thumb": {
//       color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600]
//     },
//     "&.Mui-disabled + .MuiSwitch-track": {
//       opacity: theme.palette.mode === "light" ? 0.7 : 0.3
//     }
//   },
//   "& .MuiSwitch-thumb": {
//     boxSizing: "border-box",
//     width: 15,
//     height: 16
//   },
//   "& .MuiSwitch-track": {
//     borderRadius: 26 / 2,
//     backgroundColor: theme.palette.mode === "light" ? "#0f172a5e" : "#39393D",
//     opacity: 1,
//     transition: theme.transitions.create(["background-color"], {
//       duration: 500
//     })
//   }
// }));

interface UserInfocardProps {
  userId: UserDetail;
}

export default function UserInfocard({ userId }: UserInfocardProps) {
  const dispatch = useAppDispatch();
  const { cognitoConfig } = useAppSelector((state) => state.auth);
  const { resetPasswordLoading } = useAppSelector((state) => state.userDetail);
  const [open, setOpen] = useState(false);
  const [openResetModal, setOpenResetModal] = useState(false);
  // const [userStatus, setUserStatus] = useState(userId?.user?.active);
  const [role, setRole] = useState<string | null>(null);
  const [kyc, setKyc] = useState<kycStatusType | undefined>();
  const [editRole, setEditRole] = useState<boolean>(false);
  const [editKyc, setEditKyc] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const currentRole = getCurrentRole(userId?.user?.role)?.value;

    setRole(currentRole || "");
    setKyc(userId.user.kycStatus as kycStatusType);
  }, [cognitoConfig.currentOrganisation?.organisation_id, userId]);

  // const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setUserStatus(event.target.checked);

  //   const userData = {
  //     active: !userStatus
  //   };

  //   dispatch(updateUserDetailAction(userData));
  // };

  const handleParticipantChange = (event: React.ChangeEvent<object>, value: string | null) => {
    setRole(value || "Participant");
  };

  const handleUpdateRole = () => {
    if (cognitoConfig.currentOrganisation?.organisation_id && role) {
      const data = {
        organisationId: cognitoConfig.currentOrganisation?.organisation_id,
        roleName: reverseRoleMapping[role],
        userId: String(userId?.user?.id)
      };

      dispatch(updateUserRoleAction(data)).then(() => {
        setEditRole(false);
        dispatch(fetchUserDetailAction(String(userId.user.id)));
      });
    }
  };

  const handleCloseRoleEdit = () => {
    setEditRole(false);
    dispatch(fetchUserDetailAction(String(userId.user.id)));
  };

  const handleUpdateKyc = async () => {
    if (userId?.user?.id) {
      const data = {
        userId: String(userId?.user?.id),
        kycStatus: kyc as kycStatusType
      };

      try {
        await updateKYC(data);
      } catch (error) {
        console.log(error);
      }

      setEditKyc(false);
      dispatch(fetchUserDetailAction(String(userId.user.id)));
    }
  };
  const handleCloseKycEdit = () => {
    setEditKyc(false);
    dispatch(fetchUserDetailAction(String(userId.user.id)));
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleModal = () => {
    setOpen(!open);
    handleClose();
  };

  const handleResetPassword = () => {
    dispatch(
      resetPasswordAction(String(userId?.user?.id), userId?.user?.cognitoUserName, () => {
        setOpenResetModal(false);
      })
    );
  };

  return (
    <>
      <Modalui open={open} handleClose={handleModal} aria-labelledby="edit-user-info-modal">
        <UserInfoEdiModal handleClose={handleModal} userDetail={userId} />
      </Modalui>
      <Modalui
        open={openResetModal}
        handleClose={() => setOpenResetModal(false)}
        aria-labelledby="reset-password-modal"
      >
        <div className={styles.referModalBox}>
          {resetPasswordLoading ? (
            <CircularProgress sx={{ color: "#0f172a" }} />
          ) : (
            <>
              <div className={styles.referModalTop}>
                <form>
                  <div className={styles.setting}>
                    <span>{`Are you sure you want to reset password.`}</span>
                  </div>
                </form>
              </div>
              <div className={styles.referModalBottom}>
                <div className={styles.referModalBtn}>
                  <div className={styles.btnL}>
                    <Button className={styles.reportBtn} variant="text" onClick={() => setOpenResetModal(false)}>
                      Cancel
                    </Button>
                  </div>
                  <div className={styles.btnR}>
                    <Button className={styles.sendBtn} variant="contained" onClick={handleResetPassword}>
                      Sure
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </Modalui>
      <div className={styles.userHead}>
        <div className={styles.head}>
          <div className={styles.headL}>
            <h3>User information</h3>
            <p>Edit the information about you.</p>
          </div>
          {checkPermission(CAN_EDIT_USER_INFORMATION || CAN_RESET_PASSWORD) && (
            <div className={styles.headR}>
              <IconButton onClick={handleClick} aria-label="More options">
                <MoreHorizIcon />
              </IconButton>
              <Menu id="user-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                {checkPermission(CAN_EDIT_USER_INFORMATION) && <MenuItem onClick={handleModal}>Update</MenuItem>}
                {checkPermission(CAN_RESET_PASSWORD) && (
                  <MenuItem
                    onClick={() => {
                      setOpenResetModal(true);
                      handleClose();
                    }}
                  >
                    Reset Password
                  </MenuItem>
                )}
              </Menu>
            </div>
          )}
        </div>
        <div className={styles.userInfo}>
          <div className={styles.name}>
            <div className={styles.label}>
              First name:
              <span className={styles.value}>{`${userId?.user?.initial} ${userId?.user?.firstName}`}</span>
            </div>
            <div className={styles.label}>
              Last name:
              <span className={styles.value}>{userId?.user?.lastName}</span>
            </div>
          </div>
          <div className={styles.label}>
            Username:
            <span className={styles.value}>{`${userId?.user?.userName}`}</span>
          </div>
          <div className={styles.label}>
            Mobile:
            <span className={styles.value}>{userId?.user?.phoneNumber}</span>
          </div>
          {/* {role && ( */}
          <div className={styles.roleLabel}>
            <div className={styles.label}>
              Role:
              {editRole && checkPermission(CAN_UPDATE_USER_ROLE) ? (
                <Autocomplete
                  className={styles.autoComplete}
                  options={cognitoConfig?.rolesAllowedToAdd?.map((roles: any) => roleMapping[roles]) || []}
                  filterOptions={(x) => x}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => <TextField {...params} placeholder="Select your Role.." />}
                  value={role}
                  sx={{ width: "200px" }}
                  onChange={handleParticipantChange}
                />
              ) : (
                <span className={styles.value}>{roleMapping[role || "NOROLE"] || "----"}</span>
              )}
            </div>
            {checkPermission(CAN_UPDATE_USER_ROLE) && (
              <>
                {""}
                {editRole ? (
                  <>
                    <Button className={styles.updateBtn} variant="contained" onClick={handleUpdateRole}>
                      Update
                    </Button>
                    <CloseIcon
                      onClick={handleCloseRoleEdit}
                      className={styles.closeIcon}
                      aria-label="Close edit role"
                    />
                  </>
                ) : (
                  <EditIcon
                    className={styles.editIcon}
                    onClick={() => {
                      setEditRole(true);
                    }}
                  />
                )}
              </>
            )}
          </div>
          {/* )} */}
          <div className={styles.label}>
            Address:
            <span className={styles.value}>{userId?.user?.line1 + " " + userId?.user?.line2}</span>
          </div>
          <div className={styles.bottomInfo}>
            <div className={styles.label}>
              City: <span className={styles.value}>{userId?.user?.city}</span>
            </div>
            <div className={styles.label}>
              State: <span className={styles.value}>{userId?.user?.state}</span>
            </div>
            <div className={styles.label}>
              Postcode:
              <span className={styles.value}>{userId?.user?.zipcode}</span>
            </div>
          </div>
          {/* <div className={styles.label}>
            Active:
            <FormGroup className={styles.formGroup} style={{ marginTop: "-5px", marginLeft: "4px" }}>
              <FormControlLabel
                className={styles.formControlLabel}
                disabled
                control={<IOSSwitch sx={{ m: 1 }} checked={userStatus} onChange={handleToggle} />}
                label=""
              />
            </FormGroup>
          </div> */}
          <div className={styles.roleLabel}>
            <div className={styles.label}>
              KYC Status:
              {editKyc && checkPermission(CAN_UPDATE_USER_KYC) ? (
                <Select
                  aria-label="kyc status"
                  className={styles.selectOption}
                  value={kyc}
                  onChange={(e) => {
                    setKyc(e.target.value as kycStatusType);
                  }}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    height: 30,
                    minWidth: 150
                  }}
                  // disabled={wallets?.list?.length <= 0} // Uncomment if you want to disable the select when there are no options
                >
                  <MenuItem value="HEALTHY">Pass</MenuItem>
                  <MenuItem value="UNHEALTHY">Fail</MenuItem>
                </Select>
              ) : (
                <span className={styles.value}>
                  {kyc === "HEALTHY" ? "Pass" : kyc === "UNHEALTHY" ? "Fail" : "Pending"}
                </span>
              )}
            </div>
            {checkPermission(CAN_UPDATE_USER_KYC) && (
              <>
                {""}
                {editKyc ? (
                  <>
                    <Button className={styles.updateBtn} variant="contained" onClick={handleUpdateKyc}>
                      Update
                    </Button>
                    <CloseIcon onClick={handleCloseKycEdit} className={styles.closeIcon} />
                  </>
                ) : (
                  <EditIcon
                    className={styles.editIcon}
                    onClick={() => {
                      setEditKyc(true);
                    }}
                  />
                )}
              </>
            )}
          </div>
          {userId.guardian != null && (
            <div className={styles.label}>
              Guardian:
              <Link
                style={{ textDecoration: "underline" }}
                to={`/users/${userId.guardian.id}`}
                className={styles.value}
              >
                {userId.guardian.fullName}
              </Link>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
