import { Autocomplete, Button, FormControl, MenuItem, Select, TextField } from "@mui/material";
import styles from "./report.module.scss";
import TransactionWelCard from "src/components/transactionWelCard/TransactionWelCard";
import { useSearchParams } from "react-router-dom";
import { RPRT_END_DATE, RPRT_START_DATE, RPRT_TYPE, RPRT_WALLETS } from "src/components/constant/queryConstants";
import { useAppDispatch, useAppSelector } from "src/page/store";
import { downloadReportAction, fetchWalletListAction } from "./reportSlice";
import { utc } from "moment";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

function Reports() {
  const [searchParams, setSearchParams] = useSearchParams();
  const reportType = searchParams.get(RPRT_TYPE) || "";
  const startDate = searchParams.get(RPRT_START_DATE) || "";
  const endDate = searchParams.get(RPRT_END_DATE) || "";
  const walletIds = searchParams.get(RPRT_WALLETS)?.split("&").map(Number) || [];

  const { walletList } = useAppSelector((state) => state.report);
  const { config } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [walletName, setWalletName] = useState("");
  const [error, setError] = useState({
    startDate: "",
    endDate: "",
    wallet: ""
  });

  const handleReset = () => {
    setSearchParams({});
    setError({
      ...error,
      startDate: "",
      endDate: "",
      wallet: ""
    });
  };

  useEffect(() => {
    if (startDate && new Date(startDate) <= new Date(endDate)) {
      setError({ ...error, endDate: "" });
    }
  }, [startDate, endDate, error]);

  useEffect(() => {
    if (startDate) {
      setError({ ...error, startDate: "" });
    }
  }, [error, startDate]);

  useEffect(() => {
    if (endDate) {
      setError({ ...error, endDate: "" });
    }
  }, [endDate, error]);

  useEffect(() => {
    if (walletName) {
      setError({ ...error, wallet: "" });
    }
  }, [walletName, error]);

  useEffect(() => {
    if (new Date(startDate) > new Date(endDate) && endDate !== "") {
      setError({
        ...error,
        endDate: "End date should always be larger than start date"
      });
    }
  }, [startDate, endDate, error]);

  const handleDownload = () => {
    if (!startDate.trim() || !endDate.trim() || !walletName.trim()) {
      setError({
        startDate: startDate ? "" : "Start date is required",
        endDate: endDate ? "" : "End date is required",
        wallet: walletName ? "" : "Wallet is required"
      });
      return;
    }

    const data = {
      page: 0,
      startDateInZonedDateTime: utc(searchParams.get(RPRT_START_DATE)).toISOString(),
      endDateInZonedDateTime: utc(searchParams.get(RPRT_END_DATE)).endOf("day").toISOString(),
      walletIds,
      // walletIds: [797],
      organisationIds: config?.currentOrganisation?.id ? [Number(config?.currentOrganisation?.id)] : null,
      // organisationIds: [11],
      statuses: ["Success", "Complete", "Hold"]
    };

    dispatch(downloadReportAction(data));
  };

  const fetchOptions = debounce((inputValue: string) => {
    if (!inputValue || inputValue?.length < 2) return [];
    const data = {
      page: 0,
      status: true,
      searchText: inputValue,
      limit: 10
    };

    dispatch(fetchWalletListAction(data));
  }, 500);

  const handleInputChange = (event: React.ChangeEvent<object>, newInputValue: string) => {
    fetchOptions(newInputValue.toUpperCase());
  };

  return (
    <div className={styles.reportsWrapper}>
      <div className={styles.WelCard}>
        <TransactionWelCard heading={"Reports"} subHeading={"Run reports for your SpendAble usage"} />
      </div>
      <div className={styles.reportList}>
        <div className={styles.reportListHead}>
          <div className={styles.reportListHeadIn}>
            <div className={styles.reportListHeadD}>
              <h3>Reports</h3>
              <p>Generate all kinds of report from here.</p>
            </div>
          </div>
          <div className={styles.report}>
            <div className={styles.reportType}>
              <label>Report Type :</label>
              <FormControl>
                <Select
                  value={reportType}
                  onChange={(e) => {
                    searchParams.set(RPRT_TYPE, e.target.value);
                    setSearchParams(searchParams);
                  }}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    height: 40,
                    width: "250px"
                  }}
                >
                  <MenuItem value="transactionReport">Transaction Report</MenuItem>
                </Select>
              </FormControl>
            </div>
            {reportType && (
              <div className={styles.reportFilter}>
                {reportType === "transactionReport" && (
                  <>
                    <div className={styles.filterByDate}>
                      <div className={styles.startDate}>
                        <label>Start Date :</label>
                        {/* <div className={styles.inputs}> */}
                        <TextField
                          type="date"
                          value={startDate}
                          onChange={(e) => {
                            searchParams.set(RPRT_START_DATE, e.target.value);
                            setSearchParams(searchParams);
                          }}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                        {error.startDate && (
                          <div className={styles.error}>
                            <ErrorOutlineIcon className={styles.errIcon} />
                            {error.startDate}
                          </div>
                        )}
                        {/* </div> */}
                      </div>
                      <div className={styles.endDate}>
                        <label>End Date :</label>
                        {/* <div className={styles.inputs}> */}
                        <TextField
                          type="date"
                          value={endDate}
                          onChange={(e) => {
                            searchParams.set(RPRT_END_DATE, e.target.value);
                            setSearchParams(searchParams);
                          }}
                          InputLabelProps={{
                            shrink: true,
                            classes: {
                              root: "MuiInputLabel-outlined"
                            }
                          }}
                        />
                        {error.endDate && (
                          <div className={styles.error}>
                            <ErrorOutlineIcon className={styles.errIcon} />
                            {error.endDate}
                          </div>
                        )}
                        {/* </div> */}
                      </div>
                    </div>
                    <div className={styles.byWallet}>
                      <label>Wallet:</label>
                      <div className={styles.inputs}>
                        <Autocomplete
                          options={walletList?.list}
                          filterOptions={(x) => x}
                          getOptionLabel={(option) => `${option?.description}`}
                          onInputChange={handleInputChange}
                          onChange={(e, newValue) => {
                            if (newValue?.id) {
                              searchParams.set(RPRT_WALLETS, newValue?.id.toString());
                              setWalletName(newValue?.description);
                            } else {
                              searchParams.delete(RPRT_WALLETS);
                              setWalletName("");
                            }
                            setSearchParams(searchParams);
                          }}
                          renderInput={(params) => <TextField {...params} variant="outlined" />}
                          renderOption={(props, option) => (
                            <li
                              {...props}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start"
                              }}
                            >
                              <span>
                                {option?.id}
                                {") "}
                                {option?.description}
                              </span>
                            </li>
                          )}
                          ListboxProps={{ style: { maxHeight: "200px", overflow: "auto" } }}
                        />
                        {error.wallet && (
                          <div className={styles.error}>
                            <ErrorOutlineIcon className={styles.errIcon} />
                            {error.wallet}
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}

                <div className={styles.reportBtn}>
                  <Button variant="outlined" className={styles.clearReport} onClick={handleReset}>
                    Clear
                  </Button>
                  <Button variant="contained" className={styles.downloadReport} onClick={handleDownload}>
                    Download Report
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reports;
