import axios from "axios";
import store from "src/page/store";
import { immediateToast } from "izitoast-react";
import { refreshSession } from "src/utils/utils";
import config from "src/config/Config";
import { logout } from "src/page/auth/loginSlice";
import { showErrorAlert, errorMapping } from "src/utils/errors";

const api = axios.create({
  baseURL: config.API_URL,
  headers: {
    "Content-Type": "application/json",
    partner_id: config.PARTNER_ID
  }
});

api.interceptors.request.use(async (configuration) => {
  try {
    const {
      auth: { cognitoIdToken }
    } = store.getState();
    configuration.headers["Authorization"] = `Bearer ${cognitoIdToken}`;
  } catch (error) {
    console.error(error);
  }
  return configuration;
});

api.interceptors.response.use(
  (response) => {
    if (response.data.errors) {
      const error = response.data.errors[0];
      const errorType = error.errorType;

      if (errorMapping[errorType]) {
        showErrorAlert(errorType, error.message);
      } else {
        const errorMessage = error.message;
        let messageObj: { message?: string } | undefined;

        try {
          messageObj = JSON.parse(errorMessage);
        } catch (err) {
          console.log(err);
          messageObj = undefined;
        }

        immediateToast("error", {
          message: messageObj?.message ?? errorMessage,
          timeout: 3000,
          position: "topCenter"
        });
      }

      throw new Error(error.message);
    }
    return response.data;
  },
  async function (error) {
    const originalRequest = error.config;
    const {
      auth: { cognitoRefreshToken }
    } = store.getState();
    const dispatch = store.dispatch;

    if (error.response.status === 401) {
      if (!originalRequest._retry || originalRequest._retry < 4) {
        originalRequest._retry = originalRequest._retry ? originalRequest._retry + 1 : 1;

        if (cognitoRefreshToken != null) {
          try {
            const session = await refreshSession(cognitoRefreshToken);

            axios.defaults.headers.common["Authorization"] = `Bearer ${
              (session as unknown as { idToken: string }).idToken
            }`;
            return api(originalRequest);
          } catch (err: any) {
            immediateToast("error", {
              message: err.message,
              timeout: 3000,
              position: "topCenter"
            });
            dispatch(logout());
          }
        }
      } else {
        immediateToast("error", {
          message: "Your session expired, please login again",
          timeout: 3000,
          position: "topCenter"
        });
        dispatch(logout());
      }
    }
    return Promise.reject(error);
  }
);

const client = (data: { query: string; variables?: any }) => {
  return api.post("/graphql", data);
};

export default client;
