import { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import styles from "./orderCardModal.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "src/page/store";
import {
  fetchUserDataAction,
  fetchUserListAction,
  orderCardAction,
  setUsersAction,
  setOrganisationAction
} from "src/page/cards/cardSlice";
import { debounce } from "lodash";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ToggleSwitch from "../UI/toggleSwitch/ToggleSwitch";
import { PARTICIPANT, PRIVATE, SHARED, SUPPORTER } from "../constant/constant";
import { useParams } from "react-router-dom";
import { CardAccessType, User } from "src/types/commonTypes";
import { getCurrentRole } from "src/utils/utils";
import { UserDetail } from "src/page/userDetail/userDetailSlice";

interface Iprops {
  handleClose: () => void;
  onSuccess: () => void;
  userDetail?: UserDetail;
  hideUserInput?: boolean;
  rootContainer?: string;
  closeButton?: boolean;
  secondaryButton?: boolean;
  heading?: string;
  buttonText?: {
    primaryButton?: string;
    secondaryButton?: string;
  };
}

export default function OrderCardModal({
  handleClose,
  onSuccess,
  userDetail,
  hideUserInput = false,
  rootContainer,
  closeButton = true,
  secondaryButton = true,
  heading = "Order Card",
  buttonText
}: Iprops) {
  const { users, userData, orderCardLoading } = useAppSelector((Rstate) => Rstate.cards);
  const { cognitoConfig } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [accessType, setAccessType] = useState<CardAccessType>("PRIVATE");
  const [toggleValue, setToggleValue] = useState<string>(PARTICIPANT);
  const [supporterEmail, setSupporterEmail] = useState<string>("");
  const [user, setUser] = useState<User | undefined>(undefined);
  const [firstName, setFirstName] = useState(userData?.user?.firstName || "");
  const [lastName, setLastName] = useState(
    getCurrentRole(userData?.user?.role)?.value === PARTICIPANT && accessType === "SHARED"
      ? "Supporter"
      : userData?.user?.lastName || ""
  );
  const [line1, setLine1] = useState(userData?.user?.line1 || "");
  const [line2, setLine2] = useState(userData?.user?.line2 || "");
  const [city, setCity] = useState(userData?.user?.city || "");
  const [state, setState] = useState(userData?.user?.state || "");
  const [postCode, setPostCode] = useState(userData?.user?.zipcode || "");
  const [error, setError] = useState({
    email: "",
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    state: "",
    postcode: "",
    accessType: ""
  });
  const params = useParams();

  useEffect(() => {
    if (hideUserInput && userDetail) {
      setSupporterEmail(userDetail?.user?.email);
      setFirstName(userDetail?.user?.firstName || "");
      setLastName(
        getCurrentRole(userDetail?.user?.role)?.value === PARTICIPANT && accessType === SHARED
          ? "Supporter"
          : userDetail?.user?.lastName || ""
      );
      setLine1(userDetail?.user?.line1 || "");
      setLine2(userDetail?.user?.line2 || "");
      setCity(userDetail?.user?.city || "");
      setState(userDetail?.user?.state || "");
      setPostCode(userDetail?.user?.zipcode || "");
    }
  }, [accessType, hideUserInput, userDetail]);

  const handleAddAndClose = () => {
    if (!accessType.trim()) {
      setError({
        ...error,
        accessType: !accessType ? "Access type is required" : ""
      });
      return;
    }
    if (
      !firstName.trim() ||
      !lastName.trim() ||
      !line1.trim() ||
      !city.trim() ||
      !state.trim() ||
      !postCode.trim() ||
      !accessType.trim()
    ) {
      setError({
        ...error,
        firstName: firstName ? "" : "First name is required",
        lastName: lastName ? "" : "Last name is required",
        address: line1 ? "" : "Address is required",
        city: city ? "" : "City is required",
        state: state ? "" : "State is required",
        postcode: postCode ? "" : "Postcode is required",
        accessType: !accessType ? "Access type is required" : ""
      });
      return;
    }

    const input = {
      user_id: hideUserInput ? userDetail?.user?.id + "" : userData?.user?.id + "",
      card_type: accessType,
      first_name: firstName,
      last_name: lastName,
      organisation_id: String(cognitoConfig?.currentOrganisation?.organisation_id),
      address: {
        line_1: line1,
        line_2: line2,
        city: city,
        post_code: postCode
      }
    };

    dispatch(
      orderCardAction(input, () => {
        handleClose();
        dispatch(setUsersAction([]));
        dispatch(setOrganisationAction([]));
        onSuccess();
      })
    );
  };

  const handleAddAndStay = () => {
    if (!accessType.trim()) {
      setError({
        ...error,
        accessType: !accessType ? "Access type is required" : ""
      });
      return;
    }
    if (
      !firstName.trim() ||
      !lastName.trim() ||
      !line1.trim() ||
      !city.trim() ||
      !state.trim() ||
      !postCode.trim() ||
      !accessType.trim()
    ) {
      setError({
        ...error,
        firstName: firstName ? "" : "First name is required",
        lastName: lastName ? "" : "Last name is required",
        address: line1 ? "" : "Address is required",
        city: city ? "" : "City is required",
        state: state ? "" : "State is required",
        postcode: postCode ? "" : "Postcode is required",
        accessType: !accessType ? "Access type is required" : ""
      });
      return;
    }
    const input = {
      user_id: hideUserInput ? userDetail?.user?.id + "" : userData?.user?.id + "",
      card_type: accessType,
      first_name: firstName,
      last_name: lastName,
      organisation_id: String(cognitoConfig?.currentOrganisation?.organisation_id),
      address: {
        line_1: line1,
        line_2: line2,
        city: city,
        post_code: postCode
      }
    };

    dispatch(
      orderCardAction(input, () => {
        dispatch(setUsersAction([]));
        dispatch(setOrganisationAction([]));
        onSuccess();
      })
    );
  };

  const handleCancelClose = () => {
    handleClose();
    dispatch(setUsersAction([]));
    dispatch(setOrganisationAction([]));
  };

  const handleValue = (val?: string | number | boolean) => {
    if (typeof val === "string") {
      setToggleValue(val);
      dispatch(setUsersAction([]));
      dispatch(setOrganisationAction([]));
    }
  };

  const fetchOptions = debounce((inputValue: string) => {
    if (!inputValue || inputValue?.length < 2) return [];

    let orgId: number | undefined;

    if (params.organisationId) {
      orgId = parseInt(params.organisationId);
    }

    const data = {
      from: 0,
      size: 100,
      user_filter: {
        role: toggleValue,
        full_name: inputValue
      },
      organisationId: orgId
    };

    dispatch(fetchUserListAction(data));
  }, 500);

  const handleInputChange = (event: React.ChangeEvent<object>, newInputValue: string) => {
    setSupporterEmail("");
    fetchOptions(newInputValue);
  };

  const handleSelectionChange = (event: React.ChangeEvent<object>, newValue: any) => {
    const id = newValue?.id || 0;
    const userEmail = newValue?.email || "";
    const userRole = newValue?.role || "";
    const selectedUser = users.list.find((item) => item.id === id);

    setSupporterEmail(userEmail);
    setUser(selectedUser);
    if (id) {
      dispatch(fetchUserDataAction(id));
      setLastName(userRole === PARTICIPANT && accessType === SHARED ? "Supporter" : newValue?.lastName);
    }
  };

  useEffect(() => {
    if (userData && !hideUserInput) {
      setFirstName(userData?.user?.firstName || "");
      setLastName(
        getCurrentRole(userData?.user?.role)?.value === PARTICIPANT && accessType === SHARED
          ? "Supporter"
          : userData?.user?.lastName || ""
      );
      setLine1(userData?.user?.line1 || "");
      setLine2(userData?.user?.line2 || "");
      setCity(userData?.user?.city || "");
      setState(userData?.user?.state || "");
      setPostCode(userData?.user?.zipcode || "");

      setError({
        email: "",
        firstName: "",
        lastName: "",
        address: "",
        city: "",
        state: "",
        postcode: "",
        accessType: ""
      });
    }
  }, [accessType, hideUserInput, userData]);

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError({
      email: "",
      firstName: "",
      lastName: "",
      address: "",
      city: "",
      state: "",
      postcode: "",
      accessType: ""
    });
    setAccessType(e.target.value as CardAccessType);
  };

  return (
    <div className={`${styles.referModalBox} ${rootContainer}`}>
      <main className={styles.referModalTop}>
        <form>
          <div className={styles.close}>
            <h5>{heading}</h5>
            {closeButton && (
              <button onClick={handleCancelClose} aria-label="close">
                <CloseIcon />
              </button>
            )}
          </div>
          {orderCardLoading ? (
            <div className={styles.progress}>
              <CircularProgress sx={{ color: "#0f172a" }} />
            </div>
          ) : (
            <>
              {!hideUserInput && (
                <div className={styles.email}>
                  <div className={styles.emailtoggle}>
                    <label id="user-select-label">User</label>
                    <ToggleSwitch
                      leftValueIn={"Participant"}
                      rightValueIn={"Supporter"}
                      leftValueOut={PARTICIPANT}
                      rightValueOut={SUPPORTER}
                      getValue={handleValue}
                      toggleBodyOver={styles.toggleBodyOver}
                      contentOver={styles.contentOver}
                      className={styles.myToggleSwitch}
                    />
                  </div>

                  <Autocomplete
                    options={users.list}
                    filterOptions={(x) => x}
                    getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                    onInputChange={handleInputChange}
                    onChange={handleSelectionChange}
                    value={user}
                    renderInput={(parms) => (
                      <TextField {...parms} variant="outlined" aria-labelledby="user-select-label" />
                    )}
                    renderOption={(props, option) => (
                      <li
                        {...props}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start"
                        }}
                      >
                        <span>
                          {option.firstName} {option.lastName}
                        </span>
                        <span style={{ color: "#64748B" }}>{option.email}</span>
                      </li>
                    )}
                    ListboxProps={{ style: { maxHeight: "200px", overflow: "auto" } }}
                  />

                  {error.email && (
                    <div className={styles.error}>
                      <ErrorOutlineIcon className={styles.errIcon} />
                      {error.email}
                    </div>
                  )}
                </div>
              )}
              {supporterEmail.trim() && (
                <div className={styles.formfill}>
                  <div className={styles.RadioInput}>
                    <div className={styles.inputs}>
                      <div className={styles.inputYes}>
                        <input
                          type="radio"
                          name="accessType"
                          value="PRIVATE"
                          id="private"
                          checked={accessType === PRIVATE}
                          onChange={handleRadioChange}
                        />
                        <label htmlFor="private">Card for the participant</label>
                      </div>
                      <div className={styles.inputNo}>
                        <input
                          type="radio"
                          name="accessType"
                          value="SHARED"
                          id="shared"
                          checked={accessType === SHARED}
                          onChange={handleRadioChange}
                        />
                        <label htmlFor="shared">Card for their support workers</label>
                      </div>
                    </div>
                    {error.accessType && (
                      <div className={styles.error}>
                        <ErrorOutlineIcon className={styles.errIcon} />
                        {error.accessType}
                      </div>
                    )}
                  </div>
                  {(accessType === SHARED || accessType === PRIVATE) && (
                    <>
                      <div className={styles.name}>
                        <div className={styles.Fname}>
                          <label>First Name</label>
                          <input
                            type="text"
                            placeholder="Enter Text Here"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                          {error.firstName && (
                            <div className={styles.error}>
                              <ErrorOutlineIcon className={styles.errIcon} />
                              {error.firstName}
                            </div>
                          )}
                        </div>
                        <div className={styles.Lname}>
                          <label>Last Name</label>
                          <input
                            type="text"
                            placeholder="Enter Text Here"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                          {error.lastName && (
                            <div className={styles.error}>
                              <ErrorOutlineIcon className={styles.errIcon} />
                              {error.lastName}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className={styles.addressrowI}>
                        <div className={styles.address}>
                          <label>line 1</label>
                          <input
                            type="text"
                            placeholder="Enter Text Here"
                            value={line1}
                            onChange={(e) => setLine1(e.target.value)}
                          />
                          {error.address && (
                            <div className={styles.error}>
                              <ErrorOutlineIcon className={styles.errIcon} />
                              {error.address}
                            </div>
                          )}
                        </div>
                        <div className={styles.address}>
                          <label>line 2</label>
                          <input
                            type="text"
                            placeholder="Enter Text Here"
                            value={line2}
                            onChange={(e) => setLine2(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className={styles.addressrowII}>
                        <div className={styles.city}>
                          <label>City</label>
                          <input
                            type="text"
                            placeholder="Enter Text Here"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                          />
                          {error.city && (
                            <div className={styles.error}>
                              <ErrorOutlineIcon className={styles.errIcon} />
                              {error.city}
                            </div>
                          )}
                        </div>
                        <div className={styles.state}>
                          <label>State</label>
                          <input
                            type="text"
                            placeholder="Enter Text Here"
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                          />
                          {error.state && (
                            <div className={styles.error}>
                              <ErrorOutlineIcon className={styles.errIcon} />
                              {error.state}
                            </div>
                          )}
                        </div>
                        <div className={styles.postCode}>
                          <label>PostCode</label>
                          <input
                            type="number"
                            placeholder="Enter Text Here"
                            value={postCode}
                            onChange={(e) => setPostCode(e.target.value)}
                          />
                          {error.postcode && (
                            <div className={styles.error}>
                              <ErrorOutlineIcon className={styles.errIcon} />
                              {error.postcode}
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
            </>
          )}
        </form>
      </main>
      <footer className={styles.referModalBottom}>
        <div className={styles.referModalBtn}>
          <Stack spacing={2} direction="row">
            {secondaryButton && (
              <Button
                className={styles.cancelBtn}
                variant="text"
                onClick={handleAddAndClose}
                disabled={orderCardLoading}
              >
                {orderCardLoading ? (
                  <CircularProgress size={24} style={{ color: "white" }} />
                ) : (
                  buttonText?.secondaryButton || "Order & Back To List"
                )}
              </Button>
            )}
            <Button
              className={styles.sendBtn}
              variant="contained"
              onClick={handleAddAndStay}
              disabled={orderCardLoading}
            >
              {orderCardLoading ? (
                <CircularProgress size={24} style={{ color: "white" }} />
              ) : (
                buttonText?.primaryButton || "Order Another Card"
              )}
            </Button>
          </Stack>
        </div>
      </footer>
    </div>
  );
}
