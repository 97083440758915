import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { StoreDispatch } from "../store";
// import swal from "sweetalert2";
import { AddOrgRequest, createInitialOrganisation } from "src/network/graphql/organisationService";
import { immediateToast } from "izitoast-react";
import { CreateUserRequest, createUser, getUserById } from "src/network/graphql/userService";
import { initialUserDetail } from "../userDetail/userDetailSlice";
import { CreateWalletInputType, changeWalletPreference, createWallet } from "src/network/graphql/walletService";
import { getSubscription } from "src/network/graphql/subscriptionServices";

export type SubscriptionLimits = {
  cashLimit: number;
  gneralLimit: number;
};

const initialSubscriptionLimits: SubscriptionLimits = {
  cashLimit: 0,
  gneralLimit: 0
};
interface OnBoardingState {
  onboardingLoading: boolean;
  subscriptionDetailLoading: boolean;
  userLoading: boolean;
  walletLoading: boolean;
  userDetail: typeof initialUserDetail;
  error: any;
  onboardingProgress: string;
  subscriptionLimits: SubscriptionLimits;
  clickCounterProgress: number;
}
const initialState: OnBoardingState = {
  onboardingLoading: false,
  subscriptionDetailLoading: false,
  userLoading: false,
  walletLoading: false,
  userDetail: initialUserDetail,
  onboardingProgress: "createOrganization",
  clickCounterProgress: 0,
  subscriptionLimits: initialSubscriptionLimits,
  error: null
};

const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    createInitialOrgStart: (state) => {
      state.onboardingLoading = true;
    },
    createInitialOrgSuccess: (state) => {
      state.onboardingLoading = false;
    },
    createInitialOrgFail: (state, action) => {
      state.onboardingLoading = false;
      state.error = action.payload;
    },
    addUserStart: (state) => {
      state.userLoading = true;
    },
    addUserSuccess: (state) => {
      state.userLoading = false;
    },
    addUserFail: (state, action) => {
      state.userLoading = false;
      state.error = action.payload;
    },
    fetchUserDetailSuccess: (state, action) => {
      state.userDetail = action.payload ?? initialUserDetail;
    },
    addWalletStart: (state) => {
      state.walletLoading = true;
    },
    addWalletSuccess: (state) => {
      state.walletLoading = false;
      state.error = null;
    },
    addWalletFail: (state, action) => {
      state.walletLoading = false;
      state.error = action.payload;
    },
    setOnboardingProgress: (state, action: PayloadAction<{ onboardingProgress: string; clickCounter: number }>) => {
      state.onboardingProgress = action.payload.onboardingProgress;
      state.clickCounterProgress = action.payload.clickCounter;
    },
    getSubscriptionStart: (state) => {
      state.subscriptionDetailLoading = true;
    },
    getSubscriptionSuccess: (state, action) => {
      state.subscriptionDetailLoading = false;
      state.subscriptionLimits = action.payload;
    },
    getSubscriptionFail: (state, action) => {
      state.subscriptionDetailLoading = false;
      state.subscriptionLimits = initialSubscriptionLimits;
      state.error = action.payload;
    }
  }
});

const {
  createInitialOrgStart,
  createInitialOrgSuccess,
  createInitialOrgFail,
  addUserStart,
  addUserSuccess,
  addUserFail,
  fetchUserDetailSuccess,
  addWalletStart,
  addWalletSuccess,
  addWalletFail,
  getSubscriptionStart,
  getSubscriptionFail,
  getSubscriptionSuccess
} = onboardingSlice.actions;

export const { setOnboardingProgress } = onboardingSlice.actions;

export const createInitialOrgAction = (data: AddOrgRequest, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(createInitialOrgStart());
    try {
      const response = (await createInitialOrganisation(data)) as unknown as { errors?: Error[] };

      if (!response.errors) {
        dispatch(createInitialOrgSuccess());
        if (cb) cb();
      } else {
        immediateToast("error", {
          message: response.errors[0].message,
          timeout: 3000,
          position: "topCenter"
        });
        dispatch(createInitialOrgFail(""));
      }
    } catch (error) {
      dispatch(createInitialOrgFail(error));
    }
  };
};

export const addUserAction = (data: CreateUserRequest, cb?: () => void, fcb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(addUserStart());
    try {
      const response = await createUser(data);

      dispatch(addUserSuccess());
      if (cb) cb();
      const userData = await getUserById(response.data.create_user.user_id);
      const user = userData.data.get_user;

      dispatch(
        fetchUserDetailSuccess({
          user: {
            id: user.user_id,
            cognitoUserName: user.cognito_username,
            initial: user.attributes.initial,
            firstName: user.attributes.first_name,
            lastName: user.attributes.last_name,
            email: user.email,
            userName: user.username,
            gender: user.attributes.gender,
            dob: user.attributes.date_of_birth,
            role: user.roles,
            phoneNumber: user.phone,
            line1: user.attributes.address.line_1,
            line2: user.attributes.address.line_2,
            city: user.attributes.address.city,
            state: user.attributes.address.state,
            zipcode: user.attributes.address.post_code,
            country: user.attributes.address.country,
            active: user.is_active,
            kycStatus: user.security_status,
            guardianId: user.guardian_id,
            profileImage: user.attributes.profile_image,
            displayId: user.display_id
          }
        })
      );
    } catch (error) {
      dispatch(addUserFail(error));
      if (fcb) fcb();
    }
  };
};

export const addWalletAction = (data: CreateWalletInputType, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(addWalletStart());
    try {
      const response = await createWallet(data);

      dispatch(addWalletSuccess());
      if (cb) cb();

      await changeWalletPreference({
        spendFrequency: "WEEKLY",
        spendLimit: 1000,
        cashWithdrawalAllowed: false,
        maxAllowedLimit: 1000,
        walletId: response.data.create_wallet.wallet_id
      });
    } catch (error) {
      dispatch(addWalletFail(error));
    }
  };
};

export const getSubscriptionAction = (id: string) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(getSubscriptionStart());
    try {
      const response = await getSubscription(id);
      const structuredResponse = response.data.get_subscription;

      dispatch(
        getSubscriptionSuccess({
          cashLimit: structuredResponse?.preferences?.limit?.role_wallet_limit?.CASH?.PARTICIPANT,
          gneralLimit: structuredResponse?.preferences?.limit?.role_wallet_limit?.GENERAL?.PARTICIPANT
        })
      );
    } catch (error) {
      dispatch(getSubscriptionFail(error));
    }
  };
};
export default onboardingSlice.reducer;
